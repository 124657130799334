import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Alert, Spinner, Modal } from "react-bootstrap";
import axios from "axios";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    address: "",
    email: "",
    phone: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false); // For WhatsApp tooltip

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/contact`,
        formData
      );

      if (response.status === 200) {
        setSuccessMessage("Thank you for contacting us! We'll get back to you shortly.");
        setShowModal(true);
        setFormData({
          firstname: "",
          lastname: "",
          address: "",
          email: "",
          phone: "",
          message: "",
        });
      }
    } catch (error) {
      setErrorMessage("Failed to send your message. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Container className="py-5">
      <h2 className="text-center mb-4">Contact Us</h2>
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

      {loading && (
        <div className="d-flex justify-content-center mb-4">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}

      <Form
        onSubmit={handleSubmit}
        className={`p-4 rounded shadow bg-light ${loading ? "opacity-50" : ""}`}
        disabled={loading}
      >
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="firstname">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your first name"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="lastname">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your last name"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                disabled={loading}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter your phone number"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                disabled={loading}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="message">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Enter your message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </Form.Group>
          </Col>
        </Row>

        <div className="text-center">
          <Button type="submit" variant="primary" className="px-5" disabled={loading}>
            Submit
          </Button>
        </div>
      </Form>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Message Sent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Thank you for contacting us! We have received your message.</p>
          <p>Our support team will get back to you shortly.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* WhatsApp Floating Chat Button */}
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {/* Tooltip */}
        {showTooltip && (
          <div
            style={{
              marginBottom: "8px",
              padding: "5px 10px",
              backgroundColor: "#333",
              color: "white",
              borderRadius: "5px",
              fontSize: "12px",
              whiteSpace: "nowrap",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            }}
          >
            WhatsApp us
          </div>
        )}


        {/* whatsapp container*/}
        <div
          id="whatsapp-container"
          style={{
            position: "fixed",
            bottom: "20px", // Position the container at the bottom
            right: "20px", // Position the container on the right
            display: "flex", // Use flexbox for text and icon alignment
            alignItems: "center", // Vertically align text to the middle of the icon
            zIndex: 1000,
          }}
        >
          {/* Message to the left of the WhatsApp button */}
          <span
            style={{
              marginRight: "2px", // Add space between the text and the icon
              padding: "5px 10px", // Add padding around the text
              color: "rgba(0, 0, 0, 0.7)", // Semi-transparent text color
              fontSize: "12px", // Smaller font size
              backgroundColor: "rgba(37, 211, 102, 0.2)", // Greenish transparent background
              borderRadius: "5px", // Rounded corners for the text box
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow for the message box
            }}
          >
            Whatsapp us for any help
          </span>

          {/* WhatsApp Floating Chat Button */}
          <a
            href="https://wa.me/+919744661203?text=Hello%20I%20need%20assistance"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              backgroundColor: "#25D366",
              borderRadius: "50%",
              width: "45px",
              height: "45px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            }}
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
              alt="WhatsApp"
              style={{
                width: "30px",
                height: "30px",
              }}
            />
          </a>

          {/* Global CSS Styling */}
          <style>
            {`
      @media (max-width: 768px) {
        #whatsapp-container {
          right: 40px !important; /* Move 20px further to the right in mobile view */
        }
      }
    `}
          </style>
        </div>
      </div>
    </Container>
  );
};

export default Contact;
