import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import axios from 'axios';
import Home from './components/Home';
import DonationForm from './components/DonationForm';
import SuperAdmin from './components/SuperAdmin';
import DonationView from './components/DonationView';
import DonationViewAdmin from './components/DonationViewAdmin';
import Login from './components/Login';
import Signup from './components/Signup';
import Header from './components/Header';
import Footer from './components/Footer';
import AddUpdate from './components/AddUpdate';
import PaymentSelection from './components/PaymentSelection';
import Confirmation from './components/Confirmation';
import PageNotFound from './components/PageNotFound';
import Contact from './components/Contact';
import MessageSubmissionForm from './components/MessageSubmissionForm';
import ConfirmDonationLink from './components/ConfirmDonationLink';
import SendCSV from  './components/SendCSV';
import CurrencyConverted from './components/CurrencyConverted';
import AddIsVerifiedField from './components//AddIsVerifiedField';



function App() {
  const navigate = useNavigate();
  const [hiddenAdmin, setHiddenAdmin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSuperAdminLoggedIn, setIsSuperAdminLoggedIn] = useState(false);

  const handleSignupSuccess = () => {
    navigate('/login'); // Redirect to login page after signup success
  };

  // Check admin login status
  useEffect(() => {
    const checkHiddenAdminStatus = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/admin/check-hidden-admin`, {
          headers: { 'X-FORWARDED-FOR': await getIPAddress() }
        });
        setHiddenAdmin(response.data.hiddenAdmin);
      } catch (error) {
        console.error("Error checking admin login status:", error);
      }
    };

    checkHiddenAdminStatus();
  }, []);

  const getIPAddress = async () => {
    const response = await axios.get('https://api64.ipify.org?format=json');
    return response.data.ip;
  };

  return (
    <div>
      <Header hiddenAdmin={hiddenAdmin} isLoggedIn={isLoggedIn} isSuperAdminLoggedIn={isSuperAdminLoggedIn} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/donate" element={<DonationForm />} />

        {/* New routes for payment process */}
        <Route path="/payment-selection" element={<PaymentSelection />} />
        <Route path="/confirmation" element={<Confirmation />} />

        {/* Protected admin routes */}
        <Route
          path="/admin"
          element={isLoggedIn ? <DonationView /> : <Navigate to="/login" replace />}
        />

        <Route
          path="/super-admin"
          element={isSuperAdminLoggedIn ? <SuperAdmin /> : <Navigate to="/login" replace />}
        />

        <Route
          path="/donation-view"
          element={isLoggedIn ? <DonationView /> : <Navigate to="/login" replace />}
        />
        <Route
          path="/donation-view-admin"
          element={isSuperAdminLoggedIn ? <DonationViewAdmin /> : <Navigate to="/login" replace />}
        />
        {/* Login and Signup routes */}
        <Route
          path="/login"
          element={<Login setIsLoggedIn={setIsLoggedIn} setIsSuperAdminLoggedIn={setIsSuperAdminLoggedIn} />}
        />
        <Route path="/signup" element={<Signup onSignupSuccess={handleSignupSuccess} />} />

        {/* Protected AddUpdate route */}
        <Route
          path="/add-update"
          element={isSuperAdminLoggedIn ? <AddUpdate /> : <Navigate to="/login" replace />}
        />
        <Route
          path="/message-submission"
          element={isSuperAdminLoggedIn ? <MessageSubmissionForm /> : <Navigate to="/login" replace />}
        />
        {/* Confirm donation link */}
        <Route path="/confirm-donation" element={<ConfirmDonationLink />} />
        {/* Catch-all route for 404 */}
        <Route path="*" element={<PageNotFound />} />

        <Route path="/contact" element={<Contact />} />
        <Route
          path="/send-csv"
          element={isSuperAdminLoggedIn ? <SendCSV /> : <Navigate to="/login" replace />}
        />  
          <Route
          path="/currency-converted"
          element={isSuperAdminLoggedIn ? <CurrencyConverted /> : <Navigate to="/login" replace />}
        />  
          <Route
          path="/AddIsVerifiedField"
          element={isSuperAdminLoggedIn ? <AddIsVerifiedField /> : <Navigate to="/login" replace />}
        />  

        
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
