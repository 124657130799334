import React, { useState, useEffect } from "react";
import { Container, Form, Button, Table, Alert, Spinner } from "react-bootstrap";
import apiClient from "../apiClient";

const AddIsVerifiedField = () => {
  const [donations, setDonations] = useState([]);
  const [ids, setIds] = useState("");
  const [isVerifiedValue, setIsVerifiedValue] = useState("true");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchDonations();
  }, []);

  const fetchDonations = async () => {
    try {
      const response = await apiClient.get(`/api/donations`);
      setDonations(response.data);
    } catch (error) {
      console.error("Error fetching donations:", error);
      setErrorMessage("Failed to fetch donations.");
    }
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    const idList = ids.split(",").map((id) => id.trim());
    const isVerifiedBool = isVerifiedValue === "true";

    try {
      await Promise.all(
        idList.map(async (id) => {
          const donation = donations.find((d) => d.id === id);
          if (donation) {
            const updatedDonation = {
              ...donation,
              isVerified: isVerifiedBool,
            };
            await apiClient.put(`/api/donations/${id}/single-field`, updatedDonation);
          }
        })
      );

      setSuccessMessage("Donations updated successfully.");
      fetchDonations(); // Refresh the donation list
    } catch (error) {
      console.error("Error updating donations:", error);
      setErrorMessage("Failed to update donations. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = (ids) => {
    navigator.clipboard.writeText(ids);
    setSuccessMessage("Copied IDs to clipboard.");
  };

  const nullConfirmationDonations = donations.filter((d) => !d.confirmationNumber);
  const notNullConfirmationDonations = donations.filter((d) => d.confirmationNumber);

  const nullConfirmationIds = nullConfirmationDonations.map((d) => d.id).join(", ");
  const notNullConfirmationIds = notNullConfirmationDonations.map((d) => d.id).join(", ");

  return (
    <Container className="py-5">
      <h2>Add isVerified Field</h2>
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

      <Form className="mb-4">
        <Form.Group controlId="donationIds">
          <Form.Label>Enter IDs (comma-separated)</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter donation IDs separated by commas"
            value={ids}
            onChange={(e) => setIds(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="isVerifiedValue" className="mb-3">
          <Form.Label>Set isVerified Value</Form.Label>
          <Form.Select
            value={isVerifiedValue}
            onChange={(e) => setIsVerifiedValue(e.target.value)}
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </Form.Select>
        </Form.Group>
        <Button variant="primary" onClick={handleUpdate} disabled={isLoading}>
          {isLoading ? <Spinner animation="border" size="sm" /> : "Update Donations"}
        </Button>
      </Form>

      <h3>Donations with Confirmation Number (not null)</h3>
      <Button variant="secondary" onClick={() => handleCopy(notNullConfirmationIds)} className="mb-2">
        Copy IDs (confirmationNumber is not null)
      </Button>
      {notNullConfirmationDonations.length === 0 ? (
        <Alert variant="info">No donations with confirmation number.</Alert>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Confirmation Number</th>
              <th>isVerified</th>
            </tr>
          </thead>
          <tbody>
            {notNullConfirmationDonations.map((donation) => (
              <tr key={donation.id}>
                <td>{donation.id}</td>
                <td>{donation.firstname || "N/A"}</td>
                <td>{donation.lastname || "N/A"}</td>
                <td>{donation.confirmationNumber}</td>
                <td>{donation.isVerified ? "true" : "false"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <h3>Donations with Confirmation Number (null)</h3>
      <Button variant="secondary" onClick={() => handleCopy(nullConfirmationIds)} className="mb-2">
        Copy IDs (confirmationNumber is null)
      </Button>
      {nullConfirmationDonations.length === 0 ? (
        <Alert variant="info">No donations with null confirmation number.</Alert>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Confirmation Number</th>
              <th>isVerified</th>
            </tr>
          </thead>
          <tbody>
            {nullConfirmationDonations.map((donation) => (
              <tr key={donation.id}>
                <td>{donation.id}</td>
                <td>{donation.firstname || "N/A"}</td>
                <td>{donation.lastname || "N/A"}</td>
                <td>{donation.confirmationNumber || "N/A"}</td>
                <td>{donation.isVerified ? "true" : "false"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default AddIsVerifiedField;
