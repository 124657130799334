// src/components/Home.js
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col, Button, Modal, Collapse, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Import icons
import DonationProgressBar from './DonationProgressBar';
import useIpLocation from "./useIpLocation";
import ShareButton from './ShareButton';
import apiClient from "../apiClient"; // Import the reusable Axios instance

const Home = () => {
  const [isMalayalam, setIsMalayalam] = useState(false);
  const [homeContent, setHomeContent] = useState(null);
  const [messages, setMessages] = useState([]); // State to store messages
  const [updates, setUpdates] = useState([]); // Store updates data
  const [loadingUpdates, setLoadingUpdates] = useState(false); // Loading state for updates
  const [open, setOpen] = useState(false); // Collapse state for updates section
  const [donationModal, setDonationModal] = useState({ show: false, donation: null });
  const { ipDetails } = useIpLocation();
  const [visibleMessages, setVisibleMessages] = useState(5); // Number of messages to display initially
  const messageBoxRef = useRef(null); // Initialize the ref for the scrollable box
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const [loadingHomeContent, setLoadingHomeContent] = useState(true); // Loading state for home content
  const [loadingMessages, setLoadingMessages] = useState(true); // Loading state for other API calls


  const location = useLocation();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);


  const updateView = () => {
    setIsDesktop(window.innerWidth >= 768);
  };

  useEffect(() => {
    // Add event listener for resizing
    window.addEventListener('resize', updateView);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', updateView);
    };
  }, []);


  const fetchUpdates = async () => {
    setLoadingUpdates(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/updates`);
      // Sort updates in chronological order by date
      const sortedUpdates = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setUpdates(sortedUpdates);
    } catch (error) {
      console.error("Error fetching updates:", error);
    } finally {
      setLoadingUpdates(false); // Ensure loading state is updated regardless of success or error
    }
  };

  const handleToggleUpdates = () => {
    if (!open && updates.length === 0) {
      fetchUpdates(); // Fetch updates only when first expanding if not already fetched
    }
    setOpen(!open); // Toggle open state
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Ensure the page scrolls to the top on load
  }, []);

  useEffect(() => {
    if (ipDetails?.region === "Kerala") {
      setIsMalayalam(true);
    } else {
      setIsMalayalam(false);
    }
  }, [ipDetails]);

  // Manual toggle for the user
  const toggleLanguage = () => {
    setIsMalayalam(!isMalayalam);
  };

  useEffect(() => {
    if (location.state?.showThankYouPopup) {
      setShowDialog(true); // Show the dialog if the state is passed

      // Clear the state to prevent dialog from showing on page reload
      navigate("/", { replace: true, state: {} });
    }
  }, [location, navigate]);

  const handleCloseDialog = () => setShowDialog(false);

  useEffect(() => {
    const fetchHomeData = async () => {
      try {
        setLoadingHomeContent(true); // Start loading home content
        setLoadingMessages(true);    // Start loading messages and donations

        // Fetch home content
        const homeResponse = await apiClient.get('/api/home-content');
        setHomeContent(homeResponse.data); // Set home content
        setLoadingHomeContent(false); // Stop loading spinner for home content

        // Fetch donations and messages in parallel
        const [donationsResponse, messagesResponse] = await Promise.all([
          apiClient.get('/api/donations/messages'),
          apiClient.get('/api/messages'),
        ]);

        // Sort messages: latest date first, then by firstname for messages without date
        const combinedMessages = [...donationsResponse.data, ...messagesResponse.data];

        // Separate messages with dates and without dates
        const messagesWithDates = combinedMessages.filter((msg) => msg.date);
        const messagesWithoutDates = combinedMessages.filter((msg) => !msg.date);

        // Sort messages with dates in descending order
        const sortedMessagesWithDates = messagesWithDates.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        // Combine sorted messages with dates first, then messages without dates
        const finalSortedMessages = [...sortedMessagesWithDates, ...messagesWithoutDates];

        setMessages(finalSortedMessages);
        setLoadingMessages(false);

        // Fetch incomplete donation by IP
        if (ipDetails?.ip) {
          const incompleteResponse = await apiClient.get('/api/donations/incomplete-donation', {
            params: { ipAddress: ipDetails.ip },
          });

          if (incompleteResponse.data) {
            const { id, firstname } = incompleteResponse.data; // Destructure id and firstname from the response
            setDonationModal({ show: true, donation: { id, firstname } }); // Set both id and firstname in the modal
          }
        }
      } catch (error) {
        console.error("Error fetching home data:", error);
        setLoadingHomeContent(false); // Ensure loading stops even on error
        setLoadingMessages(false);    // Ensure loading stops even on error
      }
    };

    fetchHomeData();
  }, [ipDetails?.ip, location.state]);

  if (!homeContent) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    ); // Show loading message while data is fetched
  }

  return (
    <Container
      style={{
        margin: isDesktop ? '0 auto' : '0', // Center content for desktop, no margin for mobile
        maxWidth: isDesktop ? 'calc(100% - 500px)' : '100%', // Desktop: 500px side margins, Mobile: full width
        marginTop: isDesktop ? '30px' : '0', // Add top margin for desktop, no extra margin for mobile
        padding: isDesktop ? '20px' : '15px', // Adjust padding for better spacing on desktop and mobile
      }}
    >
      {loadingMessages ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '30vh' }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading messages...</span>
          </Spinner>
        </div>
      ) : (
        <div>
          <Container className="py-5" style={{ marginTop: '-70px' }}>
            <DonationProgressBar />
          </Container>
        </div>
      )}

      <Row className="text-center mb-4">
        <Modal show={showDialog} onHide={handleCloseDialog}>
          <Modal.Header closeButton>
            <Modal.Title>Thank You!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Your donation has been successfully submitted. We deeply appreciate your support and generosity!
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseDialog}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>

      {loadingHomeContent ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading home content...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <Col>
            <h6
              style={{
                marginTop: "-80px",
                fontWeight: "bold",
                fontSize: "1.3rem", // Adjust the size if needed
                color: "#222", // Darker font color
                textAlign: "center"
              }}
            >
              {homeContent.headerText}
            </h6>
            <Button
              variant="link"
              onClick={toggleLanguage}
              className="mt-2"
              style={{ fontSize: "1rem", textAlign: "center", display: "block", margin: "0 auto" }}
            >
              {isMalayalam ? "Read in English" : "മലയാളത്തിൽ വായിക്കുക"}
            </Button>

          </Col>

          {isMalayalam ? (
            // Malayalam Content
            <>
              <Row className="justify-content-center mb-4">
                <Col md={3} className="text-center">
                  <img
                    src={homeContent.imagePath1}
                    alt="Jerome's Journey"
                    className="img-fluid rounded"
                  />
                </Col>
                {/* Blank line */}
                <div style={{ height: "1rem" }}></div>
                <Col md={8}>
                  <p className="lead" style={{ fontSize: "0.875rem" }}>
                    {homeContent.malayalamParagraph1}
                  </p>
                </Col>
              </Row>

              <Row className="justify-content-center mb-4">
                <Col md={8}>
                  <p className="lead" style={{ fontSize: "0.875rem" }}>
                    {homeContent.malayalamParagraph2}
                  </p>
                </Col>
                <Col md={3} className="text-center">
                  <img
                    src={homeContent.imagePath2}
                    alt="Family Support"
                    className="img-fluid rounded"
                  />
                </Col>
              </Row>

              <Row className="justify-content-center mb-4">
                <Col md={8}>
                  <p className="lead" style={{ fontSize: "0.875rem" }}>
                    {homeContent.malayalamParagraph3}
                  </p>
                </Col>
              </Row>

              <Row className="justify-content-center mb-4">
                <Col md={4} className="text-center">
                  <img
                    src={homeContent.imagePath3}
                    alt="Hope for Jerome"
                    className="img-fluid rounded"
                  />
                </Col>
                {/* Blank line */}
                <div style={{ height: "1rem" }}></div>
                <Col md={8}>
                  <p className="lead" style={{ fontSize: "0.875rem" }}>
                    {homeContent.malayalamParagraph4}
                  </p>
                </Col>
              </Row>

              <Row className="justify-content-center mb-4">
                <Col md={8}>
                  <p className="lead" style={{ fontSize: "0.875rem" }}>
                    {homeContent.malayalamParagraph5}
                  </p>
                </Col>
                <Col md={4} className="text-center">
                  <img
                    src={homeContent.imagePath4}
                    alt="Family Support"
                    className="img-fluid rounded"
                  />
                </Col>
              </Row>

              <Row className="justify-content-center mb-4">
                <Col md={8}>
                  <p className="lead" style={{ fontSize: "0.875rem" }}>
                    {homeContent.malayalamParagraph6}
                  </p>
                </Col>
              </Row>
            </>
          ) : (
            // English Content
            <>
              <Row className="justify-content-center mb-4">
                <Col md={3} className="text-center">
                  <img
                    src={homeContent.imagePath1}
                    alt="Jerome's Journey"
                    className="img-fluid rounded"
                  />
                </Col>
                {/* Blank line */}
                <div style={{ height: "1rem" }}></div>
                <Col md={8}>
                  <p className="lead" style={{ fontFamily: "Helvetica, Arial, sans-serif", fontSize: "1rem" }}>
                    {homeContent.englishParagraph1}
                  </p>
                </Col>
              </Row>

              <Row className="justify-content-center mb-4">
                <Col md={8}>
                  <p className="lead" style={{ fontFamily: "Helvetica, Arial, sans-serif", fontSize: "1rem" }}>
                    {homeContent.englishParagraph2}
                  </p>
                </Col>
                <Col md={3} className="text-center">
                  <img
                    src={homeContent.imagePath2}
                    alt="Family Support"
                    className="img-fluid rounded"
                  />
                </Col>
              </Row>

              <Row className="justify-content-left mb-4" style={{ transform: "translateY(10px)" }}>
                <Col md={8}>
                  <p className="lead" style={{ fontFamily: "Helvetica, Arial, sans-serif", fontSize: "1rem" }}>
                    {homeContent.englishParagraph3}
                  </p>
                </Col>
                <Col md={4} className="text-center">
                  <img
                    src={homeContent.imagePath3}
                    alt="Hope for Jerome"
                    className="img-fluid rounded"
                  />
                </Col>
              </Row>

              <Row className="justify-content-center mb-4">
                <Col md={8}>
                  <p className="lead" style={{ fontFamily: "Helvetica, Arial, sans-serif", fontSize: "1rem" }}>
                    {homeContent.englishParagraph4}
                  </p>
                </Col>
                <Col md={4} className="text-center">
                  <img
                    src={homeContent.imagePath4}
                    alt="Family Support"
                    className="img-fluid rounded"
                  />
                </Col>
              </Row>

              <Row className="justify-content-center mb-4">
                <Col md={8}>
                  <p className="lead" style={{ fontFamily: "Helvetica, Arial, sans-serif", fontSize: "1rem" }}>
                    {homeContent.englishParagraph6}
                  </p>
                </Col>
              </Row>
            </>
          )}
        </>
      )}


      {/* View Updates Button and Collapsible Section */}
      <Row className="mt-4">
        <Col className="text-start">
          <Button
            onClick={handleToggleUpdates}
            aria-controls="updates-collapse-text"
            aria-expanded={open}
            variant="outline-warning"
            className="d-flex align-items-center"
          >
            {open ? "Hide Updates" : "View Updates"}
            {open ? <FaChevronUp className="ms-2" /> : <FaChevronDown className="ms-2" />}
          </Button>
          <Collapse in={open}>
            <div
              id="updates-collapse-text"
              className="mt-3"
              style={{
                maxHeight: "50vh", // Set maximum height to half the viewport height
                overflowY: "auto", // Enable vertical scrolling
                border: "1px solid #ddd", // Optional styling for border
                borderRadius: "5px", // Optional styling for rounded corners
                padding: "10px", // Optional padding for content
                backgroundColor: "#f9f9f9", // Optional background color
                fontSize: "0.875rem", // Slightly smaller font size
              }}
            >
              {loadingUpdates ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                updates.map((update, index) => (
                  <div key={index} className="mb-3">
                    <strong>
                      {new Date(update.date).toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </strong>
                    <p style={{ fontSize: "0.875rem" }}>{update.description}</p>
                  </div>
                ))
              )}
            </div>
          </Collapse>
        </Col>
      </Row>

      {/* Add margin-top to separate from View Updates section */}
      <hr className="my-4" />
      <Row className="text-center">
        <Col>
          <div>
            <h3>Words of Support</h3>
            <p className="text-muted">Please donate to share words of support.</p>
          </div>
          <div className="text-start mt-4">
            <div
              ref={messageBoxRef}
              style={{
                maxHeight: '50vh',
                overflowY: 'auto',
                border: '1px solid #ddd',
                borderRadius: '5px',
                padding: '10px',
                fontSize: '0.875rem',
              }}
            >
              {messages.length > 0 ? (
                messages.slice(0, visibleMessages).map((message, index) => (
                  <div key={index} className="my-3">
                    <strong style={{ fontSize: '0.9rem' }}>
                      {`${message.firstname} ${message.lastname}`}
                    </strong>
                    <p style={{ fontSize: '0.85rem' }}>{message.message}</p>
                  </div>
                ))
              ) : (
                <p style={{ fontSize: '0.85rem' }}>No messages to display.</p>
              )}
            </div>

            {visibleMessages < messages.length && (
              <Button
                variant="outline-primary"
                onClick={() => {
                  setVisibleMessages((prev) => prev + 5);

                  // Ensure the message box scrolls to the bottom
                  if (messageBoxRef.current) {
                    // First bring the focus to the message box
                    messageBoxRef.current.focus();

                    // Scroll to the bottom of the message box
                    messageBoxRef.current.scrollTo({
                      top: messageBoxRef.current.scrollHeight,
                      behavior: 'smooth', // Smooth scroll effect
                    });
                  }
                }}
                className="mt-3"
              >
                View More Messages
              </Button>
            )}

            {visibleMessages >= messages.length && messages.length > 0 && (
              <>
                <p className="text-muted mt-3" style={{ fontSize: '0.8rem' }}>No more messages to show.</p>
                <Button
                  variant="outline-danger"
                  onClick={() => setVisibleMessages(5)}
                  className="mt-3"
                >
                  Close Messages
                </Button>
              </>
            )}
          </div>
        </Col>
      </Row>

      <div
        style={{
          position: 'fixed', // Always fixed for both desktop and mobile
          bottom: isDesktop ? 'auto' : '-1px', // Position at the bottom for mobile
          right: isDesktop ? '50px' : '8px', // Ensure buttons are aligned to the right margin          
          top: isDesktop ? '200px' : 'auto', // Position vertically for desktop
          display: 'flex',
          flexDirection: isDesktop ? 'column' : 'row', // Stack vertically for desktop, horizontally for mobile
          alignItems: isDesktop ? 'flex-start' : 'center', // Align items based on view
          justifyContent: isDesktop ? 'flex-start' : 'space-between', // Center horizontally for mobile
          zIndex: 1000, // Ensure buttons stay on top
          backgroundColor: isDesktop ? 'transparent' : '#fff', // Optional background for mobile
          boxShadow: isDesktop ? 'none' : '0 -2px 10px rgba(0, 0, 0, 0.1)', // Add shadow for mobile
          borderRadius: isDesktop ? '0' : '10px', // Rounded corners for mobile container
          padding: isDesktop ? '0' : '9px', // Add padding for mobile
          width: isDesktop ? 'auto' : 'calc(100% - 15px)', // Full width minus padding for mobile
        }}
      >
        {/* Share Button */}
        <div style={{ marginBottom: isDesktop ? '20px' : '0', marginRight: isDesktop ? '85px' : '4px' }}>
          <ShareButton isMalayalam={isMalayalam} />
        </div>

        {/* Donate Now Button */}
        <div>
          <Link to="/donate">
            <Button variant="info" size="lg">
              Donate
            </Button>
          </Link>
        </div>
      </div>

      <Modal show={donationModal.show} backdrop="static" centered>
        <Modal.Header>
          <Modal.Title>{isMalayalam ? "ദയവായി നിങ്ങളുടെ സംഭാവന പൂർത്തിയാക്കുക!" : "Please Complete Your Donation!"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isMalayalam ? (
            <div>
              <p>
                Dear <strong>{donationModal.donation?.firstname || 'Friend'}</strong>,
              </p>
              <p>
                താങ്കൾ സംഭാവനയുടെ പ്രക്രിയ ആരംഭിച്ചിരിക്കുന്നു, പക്ഷേ, ഞങ്ങളുടെ റെക്കോർഡ് പ്രകാരം അത് ഇതുവരെ പൂർത്തിയാക്കിയിട്ടില്ല.
              </p>
              <p>
                താങ്കൾ ഇത് ഇതിനകം പൂർത്തിയാക്കിയെങ്കിൽ, <strong>ഇടപാടിന്റെ ഐഡി</strong>യും <strong>സ്ക്രീൻഷോട്ടും </strong>താഴെ നൽകിയിരിക്കുന്ന ലിങ്ക് ഉപയോഗിച്ച് അപ്‌ലോഡ് ചെയ്യുക.
              </p>
            </div>
          ) : (
            <div>
              <p>
                Dear <strong>{donationModal.donation?.firstname || 'Friend'}</strong>,
              </p>
              <p> We noticed you started a donation but haven’t completed the process.  </p>
              <p> If you have completed the transaction  please upload the <strong>screenshot </strong> of the transaction along with the <strong>Transaction ID </strong> and submit them to us for tracking the donation payment by clicking on this link:
              </p>
            </div>
          )}

          <p>
            <a
              href={`https://www.support-jerome.org/confirm-donation?id=${donationModal.donation?.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {isMalayalam ? "സംഭാവന പൂർത്തിയാക്കാൻ ഇവിടെ ക്ലിക്ക്  ചെയ്യുക" : "Clcik Here to Complete Donation"}
            </a>
          </p>
        </Modal.Body>
        <Modal.Footer>
          {isMalayalam ? (
            <div>
              <p>
                ഇതുവരെ ഇടപാട് പൂർത്തിയാക്കിയിട്ടില്ലെങ്കിൽ, മുകളിലെ ലിങ്ക് ക്ലിക്ക് ചെയ്ത ശേഷം അനുയോജ്യമായ പേയ്‌മെന്റ് രീതി തിരഞ്ഞെടുക്കുക.
              </p>
              <p>
                പേജിൻറെ മേൽഭാഗത്ത് ഒരു <strong>നീല ബട്ടൺ</strong> കാണും, അത് അനുയോജ്യമായ പേയ്‌മെന്റ് രീതി തിരഞ്ഞെടുക്കിക്കാൻ ഉപയോഗിക്കാം.
              </p>
              <p>
                <strong>UPI / PhonePe / GPay(Google Pay) / Paytm</strong> തിരഞ്ഞെടുക്കുമ്പോൾ, ഒരു QR കോഡ് നിങ്ങളുടെ ഫോൺ ഗ്യാലറിയിൽ ഡൗൺലോഡ് ചെയ്യപ്പെടും. അതു സ്കാൻ ചെയ്ത് ഇടപാട് പൂർത്തിയാക്കാം.

              </p>
              <p>
                അതുപോലെ  തന്നെ, <strong>7011001050</strong>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => {
                    navigator.clipboard.writeText("7011001050");
                    alert("Phone number copied to clipboard!");
                  }}
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    color: "#fff",
                    backgroundColor: "#28a745", // Brighter green background
                    border: "1px solid #28a745",
                    borderRadius: "5px",
                    fontSize: "10px",
                    padding: "5px 10px",
                    cursor: "pointer",
                    transition: "0.3s ease-in-out",
                    boxShadow: "0 0 10px rgba(40, 167, 69, 0.6)", // Outer glow with green tint
                }}
                  onMouseEnter={(e) => {
                    e.target.style.boxShadow = "0 0 20px rgba(108, 117, 125, 0.9)";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.boxShadow = "0 0 10px rgba(108, 117, 125, 0.6)";
                  }}
                >
                  Click to Copy Phone
                </Button>

                ഫോൺ നമ്പർ  മുകളിൽ പറഞ്ഞ പേയ്‌മെന്റ് രീതികൾക്ക് ഉപയോഗിക്കാം.

              </p>
              <p>
                <strong>ഇന്റർനെറ്റ് ബാങ്കിംഗ്</strong> ഉപയോഗിക്കുകയാണെങ്കിൽ, അതിനു വേണ്ട വിശദാംശങ്ങൾ പേയ്മെന്റ്  പേജിൽ ലഭ്യമാകുന്നതാണ്.
              </p>
              <p>
                നിങ്ങൾക്ക് നൽകാൻ കഴിയുന്ന തുക—അതെത്ര ചെറിയതായാലും—തീർച്ചയായും സാമ്പത്തിക ഭാരങ്ങൾ ലഘൂകരിക്കാൻ ഉപകരിക്കും.
              </p>
              <p>
                നിങ്ങളുടെ ഉദാരമനസ്കതയ്ക്കു ഒത്തിരി നന്ദി രേഖപ്പെടുത്തുന്നു!
              </p>
              <p>
                താങ്കൾക്ക്  ഇപ്പോൾ സംഭാവന ചെയ്യാൻ സാധിക്കാത്ത സാഹചര്യമാണെങ്കിൽ, മുകളിൽ കൊടുത്തുട്ടുള്ള ലിങ്കിൽ ക്ലിക്ക് ചെയ്തുകൊണ്ട് ദയവായി സംഭാവനയുടെ വിശദാംശങ്ങൾ ഡിലീറ്റ്  അല്ലെങ്കിൽ റദ്ദാക്കുക.
              </p>
            </div>
          ) : (
            <div>
              <p>
                If you haven't completed the transaction yet, please choose a suitable payment method after clicking on the above link.
              </p>
              <p>
                You will see a <strong>Blue </strong>button on the top of the page for choosing a suitable payment method.
              </p>
              <p>
                Clicking on <strong> UPI / Phonepay / GPay(Google Pay) / Paytm</strong>, a QR Code will be downloaded to your phone gallery, which can be scanned for the transaction.
              </p>
              <p>
                Alternately, You can use the Phone No. <strong>7011001050</strong>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => {
                    navigator.clipboard.writeText("7011001050");
                    alert("Phone number copied to clipboard!");
                  }}
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    color: "#fff",
                    backgroundColor: "#28a745", // Brighter green background
                    border: "1px solid #28a745",
                    borderRadius: "5px",
                    fontSize: "10px",
                    padding: "5px 10px",
                    cursor: "pointer",
                    transition: "0.3s ease-in-out",
                    boxShadow: "0 0 10px rgba(40, 167, 69, 0.6)", // Outer glow with green tint
                }}
                >
                  Click to Copy Phone
                </Button>

                for the above payment methods.{' '}

              </p>
              <p>
                If you prefer <strong> Internet Banking</strong>, the details will be available to you while choosing that method.
              </p>
              <p>
                If you are outside India, you can use the <strong>Pay Pal </strong> option if you have Paypal account, otherwise <strong>  Debit / Credit Card </strong> Option will also work.
              </p>
              <p>
                Any amount you can give—no matter how small—will make a huge difference in easing the financial strain.
              </p>
              <p>
                If you're unable to donate now, please delete or cancel the donation details by clicking on the same link above.
              </p>
              <p>
                Thank you very much for your support!
              </p>
            </div>
          )}
        </Modal.Footer>
      </Modal>


    </Container>
  );
};

export default Home;
