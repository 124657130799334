import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Form, Button, Row, Col, Modal, Spinner, Alert } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import currencyCodes from "currency-codes";
import { useNavigate } from "react-router-dom";
import { CountryDropdown } from 'react-country-region-selector';
import apiClient from "../apiClient"; // Import the reusable Axios instance

const DonationForm = () => {
  const [donationData, setDonationData] = useState({
    firstname: "",
    lastname: "",
    address: "",
    country: "", // Add country field here
    email: "",
    phone: "",
    amount: "",
    currency: "INR",
    message: "",
    isHiddenMessage: false,
    ipAddress: "", // New field for IP address
    isVerified: false,
  });

  const [errors, setErrors] = useState({});
  const [defaultCountryCode, setDefaultCountryCode] = useState("IN");
  const [loading, setLoading] = useState(false); // For showing the spinner
  const [showDialog, setShowDialog] = useState(false);
  const [isMalayalam, setIsMalayalam] = useState(false);
  const [donationResponse, setDonationResponse] = useState(null); // Store response data
  const [errorMessage, setErrorMessage] = useState(""); // State for error messages
  const [countryCurrencyMap, setCountryCurrencyMap] = useState({});
  const [donationModal, setDonationModal] = useState({ show: false, donation: null });
  const [region, setRegion] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false); // State to toggle info modal
  const [infoLanguage, setInfoLanguage] = useState("English"); // State for language toggle
  const navigate = useNavigate();
  const [showWhatsApp, setShowWhatsApp] = useState(false); // State to control WhatsApp icon visibility

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWhatsApp(true); // Show WhatsApp icon after 3 seconds
    }, 3000);

    return () => clearTimeout(timer); // Cleanup timeout on component unmount
  }, []);


  const handleToggleLanguage = () => {
    setInfoLanguage((prevLanguage) => (prevLanguage === "English" ? "Malayalam" : "English"));
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const response = await axios.get("https://ipapi.co/json/");
        const { country_name, country_code, currency, region, ip } = response.data;
        setRegion(region);
        setIsMalayalam(region === "Kerala");
        setDonationData((prevData) => ({
          ...prevData,
          country: country_name || "",
          currency: currency || "USD",
          ipAddress: ip || null, // Explicitly set to null if undefined
        }));

        setDefaultCountryCode(country_code?.toLowerCase() || "us");
      } catch (error) {
        console.error("Error fetching location data:", error);

        // Fallback in case of an error
        setDonationData((prevData) => ({
          ...prevData,
          ipAddress: null, // Ensure ipAddress is explicitly null
        }));
      }
    };



    const fetchCountryCurrencyMap = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const map = {};

        response.data.forEach((country) => {
          const countryName = country.name.common;
          const currencyCode = country.currencies && Object.keys(country.currencies)[0];

          if (countryName && currencyCode) {
            map[countryName] = currencyCode;
          }
        });

        setCountryCurrencyMap(map);
      } catch (error) {
        console.error("Error fetching country-currency map:", error);

        // Add fallback data for the most common countries
        setCountryCurrencyMap({
          "India": "INR",
          "United States": "USD",
          "United Kingdom": "GBP",
          "Australia": "AUD",
          "Canada": "CAD",
          "United Arab Emirates": "AED",
          "Saudi Arabia": "SAR",
          "Kuwait": "KWD",
          "Qatar": "QAR",
          "Bahrain": "BHD",
          "Oman": "OMR",
          "Egypt": "EGP",
          "Jordan": "JOD",
          "Lebanon": "LBP",
          "Turkey": "TRY",
          "Israel": "ILS",
          "Iraq": "IQD",
          "Yemen": "YER",
          "Syria": "SYP",
          "Pakistan": "PKR",
          "Bangladesh": "BDT",
          "Sri Lanka": "LKR",
          "Nepal": "NPR",
          "Maldives": "MVR",
          "Singapore": "SGD",
          "Malaysia": "MYR",
          "Thailand": "THB",
          "Philippines": "PHP",
          "Vietnam": "VND",
          "South Korea": "KRW",
          "Japan": "JPY",
          "China": "CNY",
          "Hong Kong": "HKD",
          "Russia": "RUB",
          "South Africa": "ZAR",
          "Brazil": "BRL",
          "Argentina": "ARS",
          "Mexico": "MXN",
          "Germany": "EUR",
          "France": "EUR",
          "Italy": "EUR",
          "Spain": "EUR",
          "Portugal": "EUR",
          "Netherlands": "EUR",
          "Belgium": "EUR",
          "Austria": "EUR",
          "Switzerland": "CHF",
          "Sweden": "SEK",
          "Norway": "NOK",
          "Denmark": "DKK",
          "Finland": "EUR",
          "Poland": "PLN",
          "Czech Republic": "CZK",
          "Greece": "EUR",
          "Ireland": "EUR",
          "Hungary": "HUF",
          "Romania": "RON",
          "New Zealand": "NZD",
          "Indonesia": "IDR",
          "Nigeria": "NGN",
          "Kenya": "KES",
          "Ethiopia": "ETB",
          "Ghana": "GHS",
        });
      }
    };

    fetchLocationData();
    fetchCountryCurrencyMap();
  }, []);


  const validateField = (name, value, country) => {
    let error = "";

    if (name === "firstname" && !value.trim()) error = "First name is required";
    if (name === "lastname" && !value.trim()) error = "Last name is required";
    if (name === "address" && !value.trim()) error = "Address is required";
    if (name === "country" && !value.trim()) error = "Country is required"; // Add country validation
    if (name === "email") {
      if (!value.trim()) {
        error = "Email is required";
      } else {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          error = "Invalid email format";
        }
      }
    }
    if (name === "phone" && !value) error = "Phone number is required";
    if (name === "amount" && (!value.trim() || isNaN(value) || Number(value) <= 0))
      error = "Valid amount required";

    // Currency validation
    if (name === "currency") {
      const selectedCountry = country || donationData.country;
      const expectedCurrency = countryCurrencyMap[selectedCountry];

      if (expectedCurrency && value !== expectedCurrency) {
        error = `Currency must match the country (${expectedCurrency} for ${selectedCountry}).`;
      }
    }


    return error;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setDonationData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      if (name === "country" || name === "currency") {
        const currencyError = validateField("currency", updatedData.currency, updatedData.country);
        setErrors((prevErrors) => ({
          ...prevErrors,
          currency: currencyError,
        }));
      }

      return updatedData;
    });

    const fieldError = validateField(name, value, donationData.country);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: fieldError,
    }));
  };

  const handleCountryChange = (val) => {
    setDonationData((prevData) => ({
      ...prevData,
      country: val,
    }));

    const currencyError = validateField("currency", donationData.currency, val);
    setErrors((prevErrors) => ({
      ...prevErrors,
      country: validateField("country", val),
      currency: currencyError,
    }));
  };



  const handleBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };


  const validateAllFields = () => {
    const newErrors = {};
    let firstErrorField = null; // To store the first error field for scrolling

    Object.keys(donationData).forEach((key) => {
      const error = validateField(key, donationData[key]);
      if (error) {
        newErrors[key] = error;

        // Locate the first error field
        if (!firstErrorField) {
          firstErrorField = key;
        }
      }
    });

    setErrors(newErrors);

    if (firstErrorField) {
      // Scroll to the first error field
      const errorElement = document.getElementsByName(firstErrorField)[0];
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
        errorElement.focus();
      }
    }

    return Object.keys(newErrors).length === 0;
  };


  const handlePhoneChange = (phone) => {
    setDonationData((prevData) => ({
      ...prevData,
      phone,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      phone: validateField("phone", phone),
    }));
  };

  const handleContinueDonation = async (e) => {
    e.preventDefault();

    if (validateAllFields()) {
      setLoading(true); // Start the loading spinner
      setErrorMessage(""); // Clear any previous error message

      try {
        const currentIp = donationData.ipAddress;

        if (!currentIp) {
          throw new Error("An error occured! Please check your internet connection and refresh the page or try again later.");
        }

        const donationsResponse = await apiClient.get(`/api/donations/checkexistingdonation`, {
          params: { ipAddress: currentIp }
        });

        const existingDonation = donationsResponse.data;
        if (existingDonation) {
          setDonationModal({ show: true, donation: existingDonation });
          setLoading(false); // Stop the spinner as no further action is needed
          return;
        }

        // Initialize rupees and usDollar with null
        let rupees = null;
        let usDollar = null;

        try {
          // Fetch current exchange rates
          const exchangeRatesResponse = await axios.get("https://open.er-api.com/v6/latest/USD");
          const rates = exchangeRatesResponse.data.rates;

          const { country, currency, amount } = donationData;

          if (!amount || !currency) {
            throw new Error("Amount or currency is missing in donation data.");
          }

          const rateToINR = rates["INR"];
          const rateToCurrency = rates[currency];

          if (!rateToINR || !rateToCurrency) {
            throw new Error(`Exchange rates not available for currency: ${currency}`);
          }


          // Apply the updated conversion logic
          if (country === "India" && currency === "INR") {
            rupees = Math.round(amount); // Use the amount directly for INR
            usDollar = Math.round(amount / rateToINR); // Convert INR to USD
          } else if (country === "United States" && currency === "USD") {
            usDollar = Math.round(amount); // Use the amount directly for USD
            rupees = Math.round(amount * rateToINR); // Convert USD to INR
          } else {
            const amountInUSD = amount / rateToCurrency; // Convert to USD
            rupees = Math.round(amountInUSD * rateToINR); // Convert USD to INR
            usDollar = Math.round(amountInUSD); // Keep USD value
          }



        } catch (exchangeError) {
          console.error("Error fetching exchange rates:", exchangeError.message);
          setErrorMessage("Failed to fetch exchange rates. Please try again later.");
          setShowErrorModal(true); // Show the error modal
          return;
        }

        // Add region, current date, and converted amounts to donation data
        const currentRegion = region || "Unknown"; // Fallback if region is not set
        const currentDate = new Date().toISOString(); // ISO 8601 format for sorting

        const updatedDonationData = {
          ...donationData,
          region: currentRegion,
          date: currentDate,
          rupees: rupees !== null ? parseFloat(rupees) : null,
          usDollar: usDollar !== null ? parseFloat(usDollar) : null,
        };


        const response = await apiClient.post(
          `/api/donations/sendDonationAlertEmail`,
          updatedDonationData
        );

        if (response.status === 200) {

          setDonationResponse(response.data); // Save the response to state
          setDonationData(response.data); // Update the donation data
          setShowDialog(true); // Show dialog after successful response
        } else {
          console.warn("Failed to process donation:", response.data);
          setErrorMessage("Failed to process your donation. Please try again later."); // Set error message
          window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top
        }
      } catch (error) {
        console.error("Error processing donation:", error.message);
        setErrorMessage(error.message || "An error occurred while processing your donation. Please try again later.");
        window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top
      } finally {
        setLoading(false); // Stop the loading spinner
      }
    }
  };


  const ErrorModal = ({ show, onHide }) => (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          There is an error occurred while fetching the exchange rate. Please try to enter the
          equivalent amount in <strong>INR</strong> or <strong>USD</strong> and proceed.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const handleCloseDialog = () => {
    setShowDialog(false);
    if (donationResponse) {
      navigate("/payment-selection", { state: { donationData: donationResponse } });
    }
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)", // Slightly transparent white background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999, // Ensures it overlays other elements
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {/* Display error message */}
      {errorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}
      <Container className="py-5">

        {isMalayalam ? (
          <div>
            <Alert variant="info" className="text-center">
              <p>അടുത്ത പേജിൽ ഒരു അനുയോജ്യമായ സംഭാവന ഇടപാട് ആരംഭിക്കുന്നതിന് മുമ്പ് ദയവായി താഴെപ്പറയുന്ന വിശദാംശങ്ങൾ നൽകുക.</p>
              <p style={{ fontSize: "smaller" }}>നിങ്ങൾ ഇവിടെ നൽകുന്ന ഏതെങ്കിലും വിവരങ്ങൾ മറ്റാരുമായും ഞങ്ങൾ പങ്കിടുകയില്ലെന്നും , നിങ്ങൾ സംഭാവന ചെയ്യുന്ന തുക പൊതുജനങ്ങൾക്കു കാണിക്കുകയില്ലെന്നും ഞങ്ങൾ  ഉറപ്പു തരുന്നു .</p>  </Alert>
          </div>
        ) : (
          <div>
            <Alert variant="info" className="text-center">
              <h6 className="text-center mb-4">Please enter the following details before starting the donation process</h6>
              <p>Be assured that we never share any of your details entered here with anyone or publicly show the amount you donate</p>
            </Alert>
          </div>
        )}

        <p
          className="text-center text-primary"
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => setShowInfoModal(true)}
        >
          Please Read: How We Process a Donation.
        </p>

        <Form onSubmit={handleContinueDonation} className="p-3 border rounded bg-light">
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstname"
                  value={donationData.firstname}
                  onChange={handleChange}
                  placeholder="Enter your first name"
                  isInvalid={!!errors.firstname}
                />
                <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastname"
                  value={donationData.lastname}
                  onChange={handleChange}
                  placeholder="Enter your last name"
                  isInvalid={!!errors.lastname}
                />
                <Form.Control.Feedback type="invalid">{errors.lastname}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={donationData.address}
                  onChange={handleChange}
                  placeholder="Enter your address"
                  isInvalid={!!errors.address}
                />
                <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Country</Form.Label>
                <CountryDropdown
                  value={donationData.country}
                  onChange={(val) => handleCountryChange(val)}
                  classes={`form-control ${errors.country ? 'is-invalid' : ''}`}
                />
                {errors.country && (
                  <div className="invalid-feedback d-block">{errors.country}</div>
                )}
              </Form.Group>
            </Col>
          </Row>




          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={donationData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Phone Number (If you have a WhatsApp Number. please enter it here.) </Form.Label>
                <PhoneInput
                  country={defaultCountryCode}
                  value={donationData.phone}
                  onChange={handlePhoneChange}
                  inputProps={{ name: "phone", required: true }}
                />
                {errors.phone && <div className="text-danger mt-1">{errors.phone}</div>}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={4} md={2} className="order-1">
              <Form.Group>
                <Form.Label>Currency</Form.Label>
                <Form.Select
                  name="currency"
                  value={donationData.currency}
                  onChange={handleChange}
                  isInvalid={!!errors.currency} // Mark as invalid if there is an error
                >
                  {currencyCodes.codes().map((code) => (
                    <option key={code} value={code}>
                      {code}
                    </option>
                  ))}
                </Form.Select>
                {errors.currency && (
                  <Form.Control.Feedback type="invalid">
                    {errors.currency}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col xs={8} md={4} className="order-2">
              <Form.Group>
                <Form.Label>Donation Amount</Form.Label>
                <Form.Control
                  type="text"
                  name="amount"
                  value={donationData.amount}
                  onChange={handleChange}
                  placeholder="Enter amount"
                  isInvalid={!!errors.amount}
                />
                <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>

                {/* Noticeable description */}
                <Form.Text
                  style={{
                    fontSize: "13px", // Slightly larger font
                    color: "#007bff", // Noticeable blue color
                    fontWeight: "normal", // Make it bold
                    marginTop: "5px", // Add some spacing from the input
                    display: "flex",
                    alignItems: "center",
                    gap: "6px", // Spacing between icon and text
                  }}
                >
                  <span style={{ fontSize: "5px", color: "#007bff" }}>ℹ️</span>
                  {isMalayalam
                    ? "ഞങ്ങൾ താങ്കളുടെ സംഭാവന തുക പൊതുജനങ്ങൾക്കു കാണിക്കുകയില്ല."
                    : "We will never show the donation amount publicly."}
                </Form.Text>
              </Form.Group>


            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  name="message"
                  value={donationData.message}
                  onChange={handleChange}
                  onBlur={handleBlur} // Add this line
                  rows={3}
                  placeholder="Write a message (optional)"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Do you want to show your message to the public?</Form.Label>
                <div>
                  <Form.Check
                    type="radio"
                    label="Show"
                    name="isHiddenMessage"
                    value="false"
                    checked={!donationData.isHiddenMessage}
                    onChange={() => setDonationData((prevData) => ({ ...prevData, isHiddenMessage: false }))}
                  />
                  <Form.Check
                    type="radio"
                    label="Hide"
                    name="isHiddenMessage"
                    value="true"
                    checked={donationData.isHiddenMessage}
                    onChange={() => setDonationData((prevData) => ({ ...prevData, isHiddenMessage: true }))}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row className="text-center mt-4">
            <Col>
              <Button variant="primary" type="submit" size="lg">
                Continue Donation Process
              </Button>
            </Col>
          </Row>
        </Form>
        <Modal show={showDialog} onHide={handleCloseDialog}>
          <Modal.Header closeButton>
            <Modal.Title>Please proceed with the transaction!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isMalayalam ? (<div>
              <p>അടുത്ത പേജിൽ ഒരു അനുയോജ്യമായ സംഭാവന ഇടപാട് തിരഞ്ഞെടുത്തു മുന്നോട്ട് പോകുക! </p>
              <p> താങ്കളുടെ സാമ്പത്തിക ഇടപാട് പൂർത്തിയാക്കിയതിനു ശേഷം ഈ വെബ്‌സൈറ്റിലേക്ക് തിരിച്ചു വന്നു ട്രാൻസാക്ഷൻ ഐഡിയും സ്ക്രീൻഷോട്ടും അടുത്ത് വരുന്ന പേജിൽ അപ്‌ലോഡ് ചെയ്യാവുന്നതാണ്</p>
              <p>അതല്ലെങ്കിൽ താങ്കളുടെ ഈമെയിലിൽ വന്നിരിക്കുന്ന ഒരു ലിങ്ക് ഉപയോഗിച്ചും സ്ക്രീൻഷോട്ട് അപ്‌ലോഡ് ചെയ്യാവുന്നതാണ്.</p>
              <p>ദയവായി താങ്കളുടെ ഇമെയിൽ ഇൻബോക്സ്, കൂടാതെ ജങ്ക് അല്ലെങ്കിൽ സ്‌പാം ഫോൾഡറുകൾ പരിശോധിക്കുക! </p>
              <p> ദയവായി താഴെ കാണുന്ന ലിങ്കില്‍ ക്ലിക്ക്  ചെയ്‌തു അനുയോജ്യമായ പണമിടപാട്  മാർഗം തിരഞ്ഞെടുക്കുക.</p>
            </div>
            ) : (
              <div>

                <p>Please proceed with a suitable transaction method shown on the next page! </p>
                <p> After completing the transaction, if you are using a payment method other than Paypal / Credit / Debit Card, you are requested to comeback to the next web page and upload the Transaction ID and a screenshot.</p>
                <p>Alternately, you will be receiving an email with a link to confirm the transaction by uploading a screenshot. </p>
                <p> Please check your email inbox, also the junk or spam folder!</p>
                <p>Please click on the below button to choose a suitable payment method.</p>
              </div>
            )}

          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseDialog}>
              Proceed with Transaction
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={donationModal.show} backdrop="static" centered>
          <Modal.Header>
            <Modal.Title>{isMalayalam ? "നിങ്ങളുടെ സംഭാവന പൂർത്തിയാക്കുക" : "Please Complete Your Donation"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isMalayalam ? (
              <p>
                താങ്കൾ സംഭാവനയുടെ പ്രക്രിയ ആരംഭിച്ചിരിക്കുന്നു, പക്ഷേ, ഞങ്ങളുടെ റെക്കോർഡ് പ്രകാരം അത് ഇതുവരെ പൂർത്തിയാക്കിയിട്ടില്ല.
                അനുയോജ്യമായ ഇടപാടുമാർഗ്ഗം ഉപയോഗിച്ച് ദയവായി ഇത് പൂർത്തിയാക്കുക.
                താങ്കൾ ഇത് ഇതിനകം പൂർത്തിയാക്കിയെങ്കിൽ, <strong>ഇടപാടിന്റെ ഐഡി</strong>യും ഒരു സ്ക്രീൻഷോട്ടും താഴെ നൽകിയിരിക്കുന്ന ലിങ്ക് ഉപയോഗിച്ച് അപ്‌ലോഡ് ചെയ്യുക.
              </p>
            ) : (
              <p>
                You have already initiated the donation process but haven't completed it yet as per our records.
                Please complete it with a suitable transaction method. If you've already completed it, please
                upload the <strong>Transaction ID</strong> and a <strong>screenshot </strong> by clicking the link below.
              </p>
            )}

            <p>
              <a
                href={`https://www.support-jerome.org/confirm-donation?id=${donationModal.donation?.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {isMalayalam ? "സംഭാവന പൂർത്തിയാക്കാൻ ഇവിടെ ക്ലിക്ക്  ചെയ്യുക" : "Clcik Here to Complete Donation"}
              </a>
            </p>
          </Modal.Body>
          <Modal.Footer>
            {isMalayalam ? (
              <p>
                താങ്കൾക്ക്  ഇപ്പോൾ സംഭാവന ചെയ്യാൻ സാധിക്കാത്ത സാഹചര്യമാണെങ്കിൽ, മുകളിൽ കൊടുത്തുട്ടുള്ള ലിങ്കിൽ ക്ലിക്ക് ചെയ്തുകൊണ്ട് ദയവായി സംഭാവനയുടെ വിശദാംശങ്ങൾ ഡിലീറ്റ്  അല്ലെങ്കിൽ റദ്ദാക്കുക.
              </p>
            ) : (
              <p>
                If you're unable to donate now, please delete or cancel the donation details by clicking on the same link above.
              </p>
            )}
          </Modal.Footer>
        </Modal>
        {/* Info Modal */}
        <Modal show={showInfoModal} onHide={() => setShowInfoModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>{infoLanguage === "English" ? "Donation Process" : "സംഭാവന പ്രക്രിയ"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Language toggle link at the top */}
            <p
              className="text-center text-primary"
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                fontWeight: "bold", // Optional for emphasizing the text
              }}
              onClick={handleToggleLanguage}
            >
              {infoLanguage === "English" ? "മലയാളത്തിൽ വായിക്കുക" : "Read in English"}
            </p>

            {/* Modal content */}
            {infoLanguage === "English" ? (
              <div>
                <p>This website is an independent initiative by the <strong>Support Jerome Team</strong> to facilitate donations from India and abroad.</p>

                <p>We don't charge anything from the donations you offer, as we don't handle any banking transactions. We only keep a record of the donations made using various transaction methods.</p>

                <p>You will be sending all the transactions directly to the account of Ms. Hany Mathew, Jerome's wife.</p>

                <p>Also, Be assured that we never share your personal information with any third parties or companies.</p>

                <p>1. Fill in the required details. Ensure you provide a valid phone number and email address, as these will be used for future communications.</p>

                <p>2. On the next page, select your preferred payment method by clicking the <strong>Continue Donation Process</strong> button. Your donation details will be saved in our database, and you will receive an email with a link to update the transaction details.</p>

                <p>3. If you are in India, you can choose <strong>UPI / PhonePe / GPay (Google Pay) / Paytm</strong>. A QR Code will be downloaded to your phone gallery, which can be scanned to complete the transaction.</p>

                <p>Alternatively, you can use the  <strong>Phone Number </strong>displayed after clicking the button for the above payment methods.</p>

                <p>Please note that clicking the buttons will redirect you to the respective payment app, and you will exit our website.</p>

                <p>If you prefer <strong>Internet Banking</strong>, the relevant details will be displayed when you select this option.</p>

                <p>4. After completing the transaction, note down the <strong>Transaction ID</strong> and take a <strong>Screenshot</strong>.</p>

                <p>5. Return to the browser where the website is open. You will find a page to fill in the details of the <strong>Payment Method</strong>, the <strong>Transaction ID</strong>, and a field to upload the <strong>Screenshot</strong>.</p>

                <p>6. After entering the details, click the <strong>Confirm and Submit Donation Details</strong> button to complete the process.</p>

                <p>If you are outside India, you can use the <strong>PayPal</strong> option if you have a PayPal account. Otherwise, the <strong>Debit / Credit Card</strong> option will also work.</p>

                <p>Please be patient while the servers process your payment, as it may take some time. Please note that Paypal / Credit / Debit Cards option will deduct <strong>4% </strong>of the donation you send</p>

                <p>The above methods (<strong>PayPal</strong> and <strong>Debit / Credit Card</strong> ) are integrated with our website, so you <strong>do not</strong> need to manually enter the Transaction ID or upload a screenshot.</p>

                <p> The best option for those who are outside India, could be to select <strong> Internet Banking</strong> and note down the details then use any third party money transfer options like <strong> Wise, Remitly, Xoom, etc. </strong></p>

                <p> Then  follow step No. 4, 5 and 6 metntioned above.</p>

                <p>7. Upon successful donation, you will receive a confirmation email along with a handwritten thank-you letter from Jerome.</p>

                <p> If you want to complete the transaction later after filling the details, and close the browser, whenever you open the Home Page, you will see a link to complete the transaction.</p>

                <p> Also, there will be a link available in your email inbox / spam / junk folder for you to complete the transaction process.</p>
              </div>

            ) : (
              <div>
                <p> ഇന്ത്യയിലും വിദേശത്തുമുള്ള സംഭാവനകൾ ക്രോഡീകരിക്കുന്നതിനു,  ഈ വെബ്‌സൈറ്റ് <strong>Support Jerome Team</strong> നടത്തുന്ന സ്വതന്ത്ര സംരംഭമാണ്,</p>

                <p>നിങ്ങൾ നൽകുന്ന സംഭാവനയിൽ നിന്ന് ഞങ്ങൾ ഒന്നും ഈടാക്കുന്നില്ല, കാരണം ഞങ്ങൾ ബാങ്കിംഗ് ഇടപാടുകൾ കൈകാര്യം ചെയ്യുന്നില്ല. നിങ്ങൾ ഉപയോഗിക്കുന്ന വിവിധ പണമിടപാട് മാർഗങ്ങൾ വഴി നൽകിയ സംഭാവനകൾ ഞങ്ങൾ രേഖപ്പെടുത്തുക  മാത്രം ചെയ്യുന്നു.</p>

                <p>നിങ്ങളുടെ എല്ലാ പണമിടപാടുകളും നേരിട്ടായി ജെറോമിന്റെ ഭാര്യയായ മിസ് ഹനി മാത്യുവിന്റെ അക്കൗണ്ടിലേക്ക് അയയ്ക്കുന്നതാണ്.</p>

                <p>ഇവിടെ നല്കുന്ന താങ്കളുടെ വ്യക്തിഗത വിവരങ്ങൾ മറ്റാരുമായി പങ്കിടുകയില്ല.</p>

                <p>1. ആവശ്യമായ വിവരങ്ങൾ  പൂരിപ്പിക്കുക. താങ്കൾക്ക് പ്രവർത്തനക്ഷമമായ ഫോൺ നമ്പറും ഇമെയിൽ വിലാസവും നൽകുന്നുണ്ടെന്ന് ഉറപ്പാക്കുക, അത് ഭാവിയിലെ ആശയവിനിമയങ്ങൾക്ക് ഉപയോഗിക്കും.</p>

                <p>2. അടുത്ത പേജിൽ <strong>Continue Donation Process</strong> ബട്ടൺ ക്ലിക്ക് ചെയ്ത് താങ്കളുടെ  അനുയോജ്യമായ പണമിടപാട് രീതിയെ തിരഞ്ഞെടുക്കുക. താങ്കളുടെ സംഭാവനയുടെ വിവരങ്ങൾ ഞങ്ങളുടെ ഡാറ്റാബേസിൽ സൂക്ഷിക്കുകയും, ഇടപാട് വിശദാംശങ്ങൾ അപ്‌ഡേറ്റ് ചെയ്യാൻ ഒരു ലിങ്ക് ഉള്ള ഇമെയിൽ ലഭ്യമാക്കുകയും ചെയ്യും.</p>

                <p>3. താങ്കൾ ഇന്ത്യയിൽ ആണെങ്കിൽ, <strong>UPI / PhonePe / GPay (Google Pay) / Paytm</strong> തിരഞ്ഞെടുക്കാം. QR കോഡ് താങ്കളുടെ ഫോൺ ഗ്യാലറിയിലേക്ക് ഡൗൺലോഡ് ചെയ്യപ്പെടും, അതു സ്കാൻ ചെയ്ത് ഇടപാട് പൂർത്തിയാക്കാം.</p>

                <p>മുകളിൽ പറഞ്ഞ ഇടപാട് രീതികൾക്കായി ബട്ടൺ ക്ലിക്ക് ചെയ്താൽ ഒരു <strong>ഫോൺ നമ്പർ </strong> കാണുവാൻ കഴിയും അത് നിങ്ങള്ക്ക്  ഉപയോഗിക്കാം.</p>

                <p>ബട്ടൺ ക്ലിക്ക് ചെയ്യുമ്പോൾ പണമിടപാട്  നടത്തുവാനുള്ള  App ലിങ്കിലേയ്ക്  നിങ്ങളെ ഡയറക്റ്റ് ചെയ്തു ഈ വെബ്സൈറ്റിൽനിന്നും  പുറത്തു കടക്കുന്നതായിരിക്കും.</p>

                <p><strong>ഇന്റർനെറ്റ് ബാങ്കിംഗ്</strong> ചെയ്യണമെങ്കിൽ, അതിന് ആവശ്യമായ വിവരങ്ങൾ  ബട്ടൺ ക്ലിക്ക് ചെയ്യുമ്പോൾ ലഭ്യമാകും.</p>

                <p>4. ഇടപാട് പൂർത്തിയാക്കിയ ശേഷം <strong>Transaction ID</strong> രേഖപ്പെടുത്തുകയും <strong>Screenshot</strong> എടുക്കുകയും ചെയ്യുക.</p>

                <p>5. വെബ്‌സൈറ്റ് തുറന്നിരിക്കുന്ന ബ്രൗസറിലേക്ക് തിരികെ വരിക. അവിടെ താങ്കൾ <strong>Payment Method</strong>, <strong>Transaction ID</strong>, <strong>Screenshot</strong> അപ്‌ലോഡ് ചെയ്യാനുള്ള പേജ് കാണും.</p>

                <p>6. മുകളിലെ വിവരങ്ങൾ പൂരിപ്പിച്ചതിന് ശേഷം <strong>Confirm and Submit Donation Details</strong> ബട്ടൺ ക്ലിക്ക് ചെയ്ത് ഡൊനേഷൻ പ്രോസസ്സ് പൂർത്തിയാക്കുക.</p>

                <p>താങ്കൾ ഇന്ത്യയ്‌ക്ക് പുറത്താണെങ്കിൽ, <strong>PayPal</strong> അക്കൗണ്ട് ഉണ്ടെങ്കിൽ അത് ഉപയോഗിക്കാം. അല്ലെങ്കിൽ <strong>Debit / Credit Card</strong> വഴിയും ഇടപാട് നടത്താം.</p>

                <p>പണമിടപാട് പ്രക്രിയയ്ക്കായി സെർവർ കണക്ട് ചെയ്യുമ്പോൾ കുറച്ചു സമയമെടുക്കാം; ക്ഷമിക്കുക.</p>

                <p>മുകളിൽ പറഞ്ഞ രീതികൾ( <strong>PayPal, Debit / Credit Card </strong>  ഞങ്ങളുടെ വെബ്‌സൈറ്റിൽ സംയോജിപ്പിച്ചിരിക്കുന്നു, അതിനാൽ താങ്കൾക്ക് <strong>Transaction ID</strong> പൂരിപ്പിക്കുകയോ <strong>Screenshot</strong> അപ്‌ലോഡ്  ചെയ്യുകയോ പൂർത്തിയാക്കേണ്ടതില്ല. </p>

                <p> ഇന്ത്യക്ക് പുറത്തുള്ളവർക്ക് ഏറ്റവും നല്ല ഓപ്ഷൻ <strong>ഇന്റർനെറ്റ് ബാങ്കിംഗ്</strong> തിരഞ്ഞെടുക്കുകയും വിശദാംശങ്ങൾ കുറിച്ച് വെക്കുകയും പിന്നീട് <strong> Wise, Remitly, Xoom, etc. </strong> മുതലായ മൂന്നാം കക്ഷി പണമിടപാട് സംവിധാനങ്ങൾ ഉപയോഗിക്കുകയും ചെയ്യുക.</p>

                <p> പിന്നീട് മുകളിൽ പറഞ്ഞിരിക്കുന്ന  4, 5, 6 സ്റ്റെപ്പുകൾ പിന്തുടരുക.</p>

                <p>7. വിജയകരമായ സംഭാവനയ്ക്ക് ശേഷം, താങ്കൾക്ക് ജെറോമിന്റെ ഹസ്തരേഖയുള്ള നന്ദിപത്രവും അടങ്ങിയ ഒരു സ്ഥിരീകരണ ഇമെയിൽ ലഭിക്കും.</p>

                <p> പണമിടപാട്  പിന്നീട് ചെയ്യുവാനുള്ള  സംവിധാനവും നമ്മുടെ വെബ്‌സൈറ്റിൽ  ലഭ്യമാണ്.  പണമിടപാട് പൂർത്തിയാക്കാതെ ബ്രൌസർ ക്ലോസ്  ചെയ്തിട്ട്  നിങൾ എപ്പോൾ വേണമെങ്കിലും  തിരിച്ചുവന്നാൽ  നിങ്ങൾ പൂരിപ്പിച്ച വിവരങ്ങളടങ്ങുന്ന ലിങ്ക്  നമ്മുടെ വെബ്സൈറ്ററിന്റെ HOME പേജിൽ ലഭ്യമാക്കുന്നതാണ്. </p>

                <p> അതുകൂടാതെ  നിങ്ങളുടെ ഈമെയിലിലും  ഒരു ലിങ്ക് കാണുവാൻ കഴിയും.</p>

              </div>

            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setShowInfoModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>


        {/* whatsapp container*/}
        {showWhatsApp && (
          <div
            id="whatsapp-container"
            style={{
              position: "fixed",
              bottom: "20px", // Position the container at the bottom
              right: "20px", // Position the container on the right
              display: "flex", // Use flexbox for text and icon alignment
              alignItems: "center", // Vertically align text to the middle of the icon
              zIndex: 1000,
              animation: "popUpBounce 0.6s ease-out", // Bounce animation for the icon
            }}
          >
            {/* Message to the left of the WhatsApp button */}
            <span
              style={{
                marginRight: "10px", // Space between the text and the icon
                padding: "8px 12px", // Padding for better visibility
                color: "rgba(0, 0, 0, 0.85)", // Text color
                fontSize: "14px", // Font size for readability
                backgroundColor: "rgba(37, 211, 102, 0.3)", // Greenish background
                borderRadius: "5px", // Rounded corners
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)", // Subtle shadow
                animation: "unfoldText 7s ease-in-out forwards", // Unfold and disappear animation
                whiteSpace: "nowrap", // Prevent text wrapping
                overflow: "hidden", // Hide overflow during animations
                display: "inline-block", // Ensure inline block behavior
                transformOrigin: "right center", // Start unfolding from the right
              }}
            >
              Whatsapp us for any help
            </span>

            {/* WhatsApp Floating Chat Button */}
            <a
              href="https://wa.me/+919744661203?text=Hello%20I%20need%20assistance"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                backgroundColor: "#25D366",
                borderRadius: "50%",
                width: "50px",
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 4px 8px rgba(0,0,0,0.2)", // Enhanced shadow
                animation: "pulse 1.5s infinite", // Pulse effect for the icon
              }}
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                alt="WhatsApp"
                style={{
                  width: "32px",
                  height: "32px",
                }}
              />
            </a>

            {/* Global CSS Styling */}
            <style>
              {`
        /* Bounce animation for the WhatsApp icon */
        @keyframes popUpBounce {
          0% {
            transform: translateY(100%);
            opacity: 0;
          }
          60% {
            transform: translateY(-10%);
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }

        /* Pulse effect for the WhatsApp icon */
        @keyframes pulse {
          0% {
            transform: scale(1);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }
          50% {
            transform: scale(1.1);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
          }
          100% {
            transform: scale(1);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }
        }

        /* Unfold and disappear animation for the help text */
        @keyframes unfoldText {
          0% {
            transform: scaleX(0); /* Start folded (hidden) */
            opacity: 0; /* Invisible initially */
          }
          10% {
            transform: scaleX(1); /* Fully unfolded */
            opacity: 1; /* Fully visible */
          }
          80% {
            transform: scaleX(1); /* Remain visible */
            opacity: 1;
          }
          100% {
            transform: scaleX(0); /* Fold back to hidden */
            opacity: 0; /* Invisible */
          }
        }

        /* Mobile view adjustments */
        @media (max-width: 768px) {
          #whatsapp-container {
            right: 30px; /* Adjust position for mobile */
            bottom: 15px; /* Adjust bottom for better fit */
          }
        }
      `}
            </style>
          </div>
        )};


        <ErrorModal show={showErrorModal} onHide={() => setShowErrorModal(false)} />
      </Container>
    </div >
  );
};

export default DonationForm;
