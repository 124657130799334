import React, { useState, useEffect } from 'react';
import { ProgressBar, Container, Spinner } from 'react-bootstrap';
import useIpLocation from "./useIpLocation";
import apiClient from "../apiClient"; // Import the reusable Axios instance

const DonationProgressBar = () => {
    const [totalDonations, setTotalDonations] = useState(0); // Store total donations
    const [goal, setGoal] = useState(2000000); // Default goal in INR (₹20,00,000)
    const [loading, setLoading] = useState(true); // Loading state
    const [isIndia, setIsIndia] = useState(true); // Determine if user is in India    
    const { ipDetails, loading: ipLoading, error: ipError } = useIpLocation(); // Use IP location hook

    useEffect(() => {
        const calculateTotals = (donations) => {
            const filteredDonations = donations;
            // Calculate total INR and USD
            const totalInINR = filteredDonations.reduce((sum, donation) => sum + (donation.rupees || 0), 0);
            const totalInUSD = filteredDonations.reduce((sum, donation) => sum + (donation.usDollar || 0), 0);
            return { totalInINR, totalInUSD };
        };

        const fetchDonationDetails = async () => {
            try {
                setLoading(true);

                // Check IP details loading and errors
                if (ipLoading || ipError) {
                    console.error('IP details are not available yet or an error occurred.');
                    return;
                }

                // Validate ipDetails data
                const isInIndia = ipDetails?.country_code === 'IN';
                setIsIndia(isInIndia);

                // Fetch donations
                const donationsResponse = await apiClient.get(`/api/donations/progressbardata`);
                const donations = donationsResponse.data;

                // Calculate total donations in INR and USD
                const { totalInINR, totalInUSD } = calculateTotals(donations);

                if (isInIndia) {
                    setTotalDonations(totalInINR); // Display total in INR
                    setGoal(2000000); // Set goal to ₹20,00,000
                } else {
                    setTotalDonations(totalInUSD); // Display total in USD
                    setGoal(25000); // Set goal to $25,000
                }
            } catch (error) {
                console.error('Error fetching donation details:', error);
            } finally {
                setLoading(false);
            }
        };

        if (!ipLoading && !ipError) {
            fetchDonationDetails();
        }
    }, [ipDetails, ipLoading, ipError]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                <Spinner animation="border" role="status" variant="primary">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <Container className="py-3">
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
            >
                <span
                    style={{
                        fontSize: "1rem", // Reduced font size for the goal text
                        fontWeight: "bold",
                        color: "green",
                    }}
                >
                    Goal: {isIndia ? "₹" : "$"}
                    {goal.toLocaleString()}
                </span>
            </div>
            <div style={{ position: "relative", marginTop: "10px" }}>
                <ProgressBar
                    now={(totalDonations / goal) * 100}
                    variant="success" // Updated variant to green
                    style={{ height: "25px" }}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginTop: "10px",
                }}
            >
                <span
                    style={{
                        fontSize: "0.9rem", // Reduced font size for the raised text
                        fontWeight: "bold",
                        color: "green",
                    }}
                >
                    Raised {isIndia ? "₹" : "$"}
                    {totalDonations.toLocaleString()}
                </span>
            </div>
        </Container>
    );
};

export default DonationProgressBar;
