import React, { useState } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import apiClient from "../apiClient"; // Import the reusable Axios instance

const SendCSV = () => {
    const [email, setEmail] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Show loading state
        setSuccessMessage(""); // Clear previous messages
        setErrorMessage("");

        try {
            const encodedEmail = encodeURIComponent(email.trim()); // URL-encode email

            const response = await apiClient.post(
                `/api/donations/send-donation-csv`,
                null,
                { params: { recipientEmail: encodedEmail } }
            );

            if (response.status === 200) {
                setSuccessMessage(`CSV email sent successfully to ${email}`);
                setEmail(""); // Clear the email field
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessage(
                    `Failed to send CSV email: ${error.response.data.message || "Unknown error"}`
                );
            } else {
                setErrorMessage("Failed to send CSV email. Please try again.");
            }
            console.error("Error:", error);
        } finally {
            setIsLoading(false); // Hide loading state
        }
    };

    return (
        <Container className="py-5">
            <h2>Send Donation CSV</h2>
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Recipient Email</Form.Label>
                    <Form.Control
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter recipient email"
                        required
                    />
                </Form.Group>
                <Button variant="primary" type="submit" disabled={isLoading}>
                    {isLoading ? "Sending..." : "Send CSV"}
                </Button>
            </Form>
        </Container>
    );
};

export default SendCSV;
