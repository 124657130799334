import axios from "axios";
import { getToken } from "firebase/app-check";
import { appCheck } from "./firebaseConfig"; // Import App Check from firebaseConfig.js

// Create an Axios instance
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Set your API base URL 
});
// Add an interceptor to attach the App Check token to requests
apiClient.interceptors.request.use(
  async (config) => {
    try {
      // Fetch the App Check token
      const appCheckToken = await getToken(appCheck, /* forceRefresh= */ false);     
      if (appCheckToken?.token) {
        config.headers["X-Firebase-AppCheck"] = appCheckToken.token; // Attach the token to the headers
      }
    } catch (error) {
      console.error("Error fetching App Check token:", error);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default apiClient;
