// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import { initializeApp } from "firebase/app"; 
import { getStorage } from "firebase/storage"; 
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyAa1HEXqk4ybbONakuAkeoYzRGpPlYbB9w",
  authDomain: "support-jerome.firebaseapp.com",
  projectId: "support-jerome",
  storageBucket: "support-jerome.firebasestorage.app",
  messagingSenderId: "197882786657",
  appId: "1:197882786657:web:c90c59a18567c53acf8b20",
  measurementId: "G-7DX5HWY567"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Storage
const storage = getStorage(firebaseApp);

// Initialize App Check
const appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider("6LcA3a8qAAAAAB0hj1RhjS6lnI63EGUNFcLp-1E_"),
    isTokenAutoRefreshEnabled: true,
});

export { storage, appCheck };
export default firebaseApp;