import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Form, Button, Table, Alert, Spinner } from "react-bootstrap";
import { Clipboard } from "react-bootstrap-icons";
import apiClient from "../apiClient"; // Import the reusable Axios instance

const CurrencyConverted = () => {
  const [donations, setDonations] = useState([]);
  const [id, setId] = useState("");
  const [selectedDonation, setSelectedDonation] = useState(null);
  const [exchangeRates, setExchangeRates] = useState({});
  const [previewValues, setPreviewValues] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Fetch donations and exchange rates on component load
  useEffect(() => {
    fetchDonations();
    fetchExchangeRates();
  }, []);

  const fetchDonations = async () => {
    try {
      const response = await apiClient.get(`/api/donations`);
      setDonations(response.data);
    } catch (error) {
      console.error("Error fetching donations:", error);
      setErrorMessage("Failed to fetch donations.");
    }
  };

  const fetchExchangeRates = async () => {
    try {
      const response = await axios.get("https://open.er-api.com/v6/latest/USD");
      setExchangeRates(response.data.rates);
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
      setErrorMessage("Failed to fetch exchange rates.");
    }
  };

  const calculatePreviewValues = (donation) => {
    if (!donation || !exchangeRates) return null;

    const { country, currency, amount } = donation;
    const rateToINR = exchangeRates["INR"];
    const rateToCurrency = exchangeRates[currency];

    if (!rateToINR || !rateToCurrency) {
      setErrorMessage(`Exchange rate not found for currency: ${currency}`);
      return null;
    }

    let rupees = 0;
    let usDollar = 0;

    if (country === "India" && currency === "INR") {
      rupees = Math.round(amount);
      usDollar = Math.round(amount / rateToINR);
    } else if (country === "United States" && currency === "USD") {
      usDollar = Math.round(amount);
      rupees = Math.round(amount * rateToINR);
    } else {
      const amountInINR = Math.round(amount * rateToINR / rateToCurrency);
      rupees = amountInINR;
      usDollar = Math.round(amountInINR / rateToINR);
    }

    return { rupees, usDollar };
  };

  const handlePreview = () => {
    const donation = donations.find((d) => d.id === id);
    if (!donation) {
      setErrorMessage("Invalid ID. Donation not found.");
      return;
    }
    const calculatedValues = calculatePreviewValues(donation);
    if (calculatedValues) {
      setSelectedDonation(donation);
      setPreviewValues(calculatedValues);
    }
  };

  const handleUpdate = async () => {
    if (!selectedDonation || !previewValues) return;

    setIsLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      const updatedDonation = {
        ...selectedDonation,
        rupees: previewValues.rupees,
        usDollar: previewValues.usDollar,
      };

      const response = await apiClient.put(
        `/api/donations/${selectedDonation.id}/single-field`,
        updatedDonation
      );

      if (response.status === 200) {
        setSuccessMessage("Donation updated successfully.");
        fetchDonations();
        setSelectedDonation(null);
        setPreviewValues(null);
        setId("");
      }
    } catch (error) {
      console.error("Error updating donation:", error);
      setErrorMessage("Failed to update donation. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setSelectedDonation(null);
    setPreviewValues(null);
    setId("");
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSuccessMessage(`Copied ID: ${text}`);
  };

  return (
    <Container className="py-5">
      <h2>Currency Converter for Donations</h2>
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

      {!selectedDonation ? (
        <>
          <Form className="mb-4">
            <Form.Group controlId="donationId">
              <Form.Label>Donation ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter donation ID"
                value={id}
                onChange={(e) => setId(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" onClick={handlePreview} disabled={isLoading}>
              {isLoading ? <Spinner animation="border" size="sm" /> : "Preview Conversion"}
            </Button>
          </Form>

          <h3>Donation Records</h3>
          {isLoading ? (
            <Spinner animation="border" />
          ) : donations.length === 0 ? (
            <Alert variant="info">No donations available.</Alert>
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Country</th>
                  <th>Currency</th>
                  <th>Amount</th>
                  <th>Rupees</th>
                  <th>US Dollars</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {donations.map((donation) => (
                  <tr key={donation.id}>
                    <td>
                      {donation.id}{" "}
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => handleCopy(donation.id)}
                      >
                        <Clipboard /> Copy
                      </Button>
                    </td>
                    <td>{donation.firstname || "N/A"}</td>
                    <td>{donation.lastname || "N/A"}</td>
                    <td>{donation.country || "N/A"}</td>
                    <td>{donation.currency || "N/A"}</td>
                    <td>{donation.amount || "N/A"}</td>
                    <td>{donation.rupees || "N/A"}</td>
                    <td>{donation.usDollar || "N/A"}</td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => setId(donation.id)}
                      >
                        Set ID
                      </Button>
                    </td>
                  </tr>
                ))}
                {/* Add the total sum row at the bottom */}
                <tr>
                  <td colSpan="6" style={{ fontWeight: "bold", textAlign: "right" }}>
                    Total
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    ₹{donations.reduce((sum, donation) => sum + (donation.rupees || 0), 0).toLocaleString()}
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    ${donations.reduce((sum, donation) => sum + (donation.usDollar || 0), 0).toLocaleString()}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          )}

        </>
      ) : (
        <>
          <h3>Preview Conversion for ID: {selectedDonation.id}</h3>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Field</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Country</td>
                <td>{selectedDonation.country}</td>
              </tr>
              <tr>
                <td>Currency</td>
                <td>{selectedDonation.currency}</td>
              </tr>
              <tr>
                <td>Amount</td>
                <td>{selectedDonation.amount}</td>
              </tr>
              <tr>
                <td>Rupees (INR)</td>
                <td>{previewValues.rupees}</td>
              </tr>
              <tr>
                <td>US Dollars (USD)</td>
                <td>{previewValues.usDollar}</td>
              </tr>
            </tbody>
          </Table>
          <Button variant="success" onClick={handleUpdate} disabled={isLoading}>
            {isLoading ? <Spinner animation="border" size="sm" /> : "OK"}
          </Button>{" "}
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </>
      )}
    </Container>
  );
};

export default CurrencyConverted;
