import React, { useEffect, useState } from "react";
import {
    Container,
    Form,
    Row,
    Col,
    Button,
    Alert,
    Card,
    Modal,
    Spinner

} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Import Firebase storage utilities
import { storage } from "../firebaseConfig"; // Import your Firebase configuration
import apiClient from "../apiClient"; // Import the reusable Axios instance

const ConfirmDonationLink = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [donationData, setDonationData] = useState({});
    const [paymentMethod, setPaymentMethod] = useState("");
    const [confirmationNumber, setConfirmationNumber] = useState("");
    const [screenshot, setScreenshot] = useState(null);
    const [errors, setErrors] = useState({});
    const [showDialog, setShowDialog] = useState(false);
    const [isMalayalam, setIsMalayalam] = useState(false);
    const [loading, setLoading] = useState(false); // For showing the spinner

    const donationId = new URLSearchParams(location.search).get("id");
    const [showWhatsApp, setShowWhatsApp] = useState(false); // State to control WhatsApp icon visibility

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowWhatsApp(true); // Show WhatsApp icon after 3 seconds
        }, 6000);

        return () => clearTimeout(timer); // Cleanup timeout on component unmount
    }, []);

    // Fetch user location to determine language preference
    const fetchUserLocation = async () => {
        try {
            const response = await fetch("https://ipapi.co/json/");
            const userState = (await response.json()).region;
            setIsMalayalam(userState === "Kerala");
        } catch (error) {
            console.error("Error fetching user location:", error);
            setIsMalayalam(false); // Default to English on error
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    });


    useEffect(() => {
        const validateDonationId = () => {
            if (!donationId) {
                alert("Invalid or missing donation ID. Redirecting to the home page.");
                navigate("/");
                return false; // Prevent further execution
            }
            return true;
        };

        const fetchDonationData = async () => {
            setLoading(true); // Show loading spinner
            try {
                const storedData = sessionStorage.getItem("donationData");
                if (storedData) {
                    setDonationData(JSON.parse(storedData)); // Use data from sessionStorage if available
                    setPaymentMethod(JSON.parse(storedData).paymentMethod || "");
                    return;
                }

                const response = await apiClient.get(`/api/donations/${donationId}`);
                if (response.status === 200 && response.data) {
                    if (response.data.confirmationNumber) {
                        alert("You have already updated the donation details!");
                        navigate("/");
                    } else {
                        setDonationData(response.data);
                        setPaymentMethod(response.data.paymentMethod || "");
                        sessionStorage.setItem("donationData", JSON.stringify(response.data)); // Store data in sessionStorage
                    }
                } else {
                    alert("Donation details not found. Redirecting to the home page.");
                    navigate("/");
                }
            } catch (error) {
                console.error("Error fetching donation data:", error);
                alert("Failed to fetch donation details. Redirecting to the home page.");
                navigate("/");
            } finally {
                setLoading(false); // Hide loading spinner
            }
        };

        const initializeComponent = async () => {
            try {
                // Fetch user location for language preference
                await fetchUserLocation();

                // Validate donation ID and fetch donation data
                if (validateDonationId()) {
                    await fetchDonationData();
                }
            } catch (error) {
                console.error("Error initializing component:", error);
            }
        };

        initializeComponent();
    }, [donationId, navigate]);




    const validateFields = () => {
        const newErrors = {};
        if (!paymentMethod || paymentMethod === "") {
            newErrors.paymentMethod = "Please select a payment method.";
        }
        if (!confirmationNumber.trim()) newErrors.confirmationNumber = "Required.";
        if (!screenshot) newErrors.screenshot = "Screenshot required.";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleFileChange = (e) => {
        setScreenshot(e.target.files[0]);
    };

    const uploadScreenshotToStorage = async (file) => {
        const storageRef = ref(storage, `screenshots/${file.name}`);
        await uploadBytes(storageRef, file);
        return await getDownloadURL(storageRef);
    };
    const handleDelete = async (id) => {
        if (!id) {
            alert("Invalid donation ID. Cannot delete.");
            return;
        }

        if (window.confirm('Are you sure you want to delete the details of this donation? This action cannot be undone.')) {
            setLoading(true); // Show spinner
            try {
                // API call to delete the donation
                await apiClient.delete(`/api/donations/${id}`);

                // Optionally update UI to reflect deletion
                setDonationData({}); // Clear donation data
                alert('Donation details deleted successfully.');
                navigate('/'); // Redirect to the home page               
            } catch (error) {
                console.error('Error deleting donation:', error);
                alert('Failed to delete the donation. Please try again later.');
            } finally {
                setLoading(false); // Hide spinner
            }
        }
    };

    const handleConfirm = async () => {
        setLoading(true); // Start loading spinner

        if (!validateFields()) {
            setLoading(false); // Stop the spinner if validation fails
            return; // Exit early
        }

        try {
            const screenshotUrl = await uploadScreenshotToStorage(screenshot);

            const submissionData = {
                ...donationData,
                paymentMethod,
                confirmationNumber,
                screenshotUrl,
            };

            // Submit the donation and send emails
            const response = await apiClient.put(`/api/donations/${submissionData.id}`,
                submissionData
            );

            if (response.status === 200) {
                setShowDialog(true); // Show thank-you dialog on success
            }
        } catch (error) {
            console.error('Error submitting donation:', error);
            alert('Failed to submit donation details.');
        } finally {
            setLoading(false); // Stop the spinner after completion
        }
    };


    const handleCloseDialog = () => {
        setShowDialog(false);
        navigate("/");
    };

    return (
        <div>
            {loading && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(255, 255, 255, 0.8)", // Slightly transparent white background
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999, // Ensures it overlays other elements
                    }}
                >
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}
            <Container className="py-5">
                <button
                    onClick={() => navigate("/payment-selection")}
                    style={{
                        position: "fixed",
                        top: "80px", // Adjust for header height
                        left: "10px",
                        zIndex: 1000,
                        padding: "5px 10px", // Smaller padding for half-size button
                        fontSize: "12px", // Reduced font size
                        fontWeight: "bold",
                        color: "#fff",
                        backgroundColor: "#0000FF",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        animation: "zoom 1.5s infinite",
                        transformOrigin: "center", // Ensures smooth scaling
                    }}
                >
                    Click here to Choose a Payment Method
                </button>

                <h2 className="text-center mb-4">Confirm Donation</h2>

                <Alert variant="info">
                    {isMalayalam ? (
                        <>
                            ദയവായി താഴെപ്പറയുന്ന വിവരങ്ങൾ (<strong>ഇടപാട് ഐഡി, സ്ക്രീൻഷോട്ട്</strong> മുതലായവ)
                            <strong>ഇടപാട് പൂർത്തിയാക്കിയതിന് ശേഷം</strong> മാത്രമേ പൂരിപ്പിക്കേണ്ടതുള്ളു.

                            താങ്കൾ ഇനിയും ഇടപാട് പൂർത്തിയാക്കിയിട്ടില്ലെങ്കിൽ, മേലിൽ കാണുന്ന ബട്ടൺ ക്ലിക്ക് ചെയ്ത് അനുയോജ്യമായ ഇടപാടു മാർഗ്ഗം തിരഞ്ഞെടുക്കുക.

                            താങ്കൾ നൽകിയ ഏതെങ്കിലും വിവരങ്ങൾ തിരുത്തേണ്ട ആവശ്യമുണ്ടെങ്കിൽ, ദയവായി താഴെ കാണുന്ന ബട്ടൺ ഉപയോഗിച്ച് ഈ വിവരങ്ങൾ ഡിലീറ്റ് ചെയ്യുക,
                            പിന്നീടു ഹോം പേജിലെ 'Donate' ബട്ടൺ ഉപയോഗിച്ച് വീണ്ടും ആരംഭിക്കുക.
                        </>
                    ) : (
                        <>
                            Please fill the details below (<strong>Transaction ID, Screenshot,</strong> etc.)
                            <strong>after completing the transaction</strong> only.

                            If you haven't completed the transaction, please click on the above button and choose a suitable transaction method.

                            In case you need to correct any details, please delete this using the button below and start over again using the 'Donate' button on the Home Page.
                        </>
                    )}
                </Alert>
                <Card className="p-4">
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="name">
                                    <Form.Label><strong>First Name:</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="firstname"
                                        value={donationData.firstname || ""}
                                        readOnly
                                        style={{ backgroundColor: '#f0f0f0' }}
                                    />
                                    {errors.firstnamename && <Form.Text className="text-danger">{errors.firstname}</Form.Text>}
                                </Form.Group>
                                <Form.Group controlId="lastname">
                                    <Form.Label><strong>Last Name:</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="lastname"
                                        value={donationData.lastname || ""}
                                        readOnly
                                        style={{ backgroundColor: '#f0f0f0' }}
                                    />
                                    {errors.lastname && <Form.Text className="text-danger">{errors.lastname}</Form.Text>}
                                </Form.Group>
                                <Form.Group controlId="address" className="mt-3">
                                    <Form.Label><strong>Address:</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="address"
                                        value={donationData.address || ""}
                                        readOnly
                                        style={{ backgroundColor: '#f0f0f0' }}
                                    />
                                    {errors.address && <Form.Text className="text-danger">{errors.address}</Form.Text>}
                                </Form.Group>
                                <Form.Group controlId="country" className="mt-3">
                                    <Form.Label><strong>Country:</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="country"
                                        value={donationData.country || ""}
                                        readOnly
                                        style={{ backgroundColor: '#f0f0f0' }}
                                    />
                                    {errors.country && <Form.Text className="text-danger">{errors.country}</Form.Text>}
                                </Form.Group>

                                <Form.Group controlId="email" className="mt-3">
                                    <Form.Label><strong>Email:</strong></Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={donationData.email || ""}
                                        readOnly
                                        style={{ backgroundColor: '#f0f0f0' }}
                                    />
                                    {errors.email && <Form.Text className="text-danger">{errors.email}</Form.Text>}
                                </Form.Group>
                                <Form.Group controlId="phone" className="mt-3">
                                    <Form.Label><strong>Phone:</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phone"
                                        value={donationData.phone || ""}
                                        readOnly
                                        style={{ backgroundColor: '#f0f0f0' }}
                                    />
                                    {errors.phone && <Form.Text className="text-danger">{errors.phone}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="currency" className="mt-3">
                                    <Form.Label><strong>Currency:</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="currency"
                                        value={donationData.currency || ""}
                                        readOnly
                                        style={{ backgroundColor: '#f0f0f0' }}
                                    />
                                    {errors.currency && <Form.Text className="text-danger">{errors.currency}</Form.Text>}
                                </Form.Group>
                                <Form.Group controlId="amount" className="mt-3">
                                    <Form.Label><strong>Amount:</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="amount"
                                        value={donationData.amount || ""}
                                        readOnly
                                        style={{ backgroundColor: '#f0f0f0' }}
                                    />
                                    {errors.amount && <Form.Text className="text-danger">{errors.amount}</Form.Text>}
                                </Form.Group>
                                <Form.Group controlId="message" className="mt-3">
                                    <Form.Label><strong>Message:</strong></Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        value={donationData.message || ""}
                                        onChange={(e) =>
                                            setDonationData((prevData) => ({
                                                ...prevData,
                                                message: e.target.value,
                                            }))
                                        }
                                        placeholder="Enter your message"
                                    />
                                    {errors.message && (
                                        <Form.Text className="text-danger">{errors.message}</Form.Text>
                                    )}
                                </Form.Group>
                                <Form.Group className="mt-3">
                                    <Form.Label>Do you want to show your message to the public?</Form.Label>
                                    <div>
                                        <Form.Check
                                            type="radio"
                                            label="Show"
                                            name="isHiddenMessage"
                                            value="false"
                                            checked={!donationData.isHiddenMessage}
                                            onChange={() => setDonationData((prevData) => ({
                                                ...prevData,
                                                isHiddenMessage: false,
                                            }))}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="Hide"
                                            name="isHiddenMessage"
                                            value="true"
                                            checked={donationData.isHiddenMessage}
                                            onChange={() => setDonationData((prevData) => ({
                                                ...prevData,
                                                isHiddenMessage: true,
                                            }))}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="paymentMethod" className="mt-3">
                                    <Form.Label><strong>Payment Method:</strong></Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={paymentMethod}
                                        onChange={(e) => setPaymentMethod(e.target.value)}
                                    >
                                        <option value="" disabled>
                                            Select Payment Method
                                        </option>
                                        <option value="PayPal">PayPal</option>
                                        <option value="Credit / Debit Card">Credit / Debit Card</option>
                                        <option value="UPI / Phone Pe">UPI / Phone Pe</option>
                                        <option value="Google Pay">Google Pay</option>
                                        <option value="PayTM">PayTM</option>
                                        <option value="Internet Banking">Internet Banking</option>
                                    </Form.Control>
                                    {errors.paymentMethod && (
                                        <Form.Text className="text-danger">{errors.paymentMethod}</Form.Text>
                                    )}
                                </Form.Group>
                                <Form.Group controlId="confirmationNumber" className="mt-3">
                                    <Form.Label><strong>Transaction Number / ID:</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={confirmationNumber}
                                        onChange={(e) => setConfirmationNumber(e.target.value)}
                                        placeholder="Enter Transaction ID"
                                        required
                                    />
                                    {errors.confirmationNumber && (
                                        <Form.Text className="text-danger">{errors.confirmationNumber}</Form.Text>
                                    )}
                                </Form.Group>
                                <Form.Group controlId="screenshot" className="mt-3">
                                    <Form.Label><strong>Upload Screenshot:</strong></Form.Label>
                                    <Form.Control
                                        type="file"
                                        onChange={handleFileChange}
                                        accept="image/*"
                                        required
                                    />
                                    {errors.screenshot && (
                                        <Form.Text className="text-danger">{errors.screenshot}</Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="text-center mt-4">
                            <Col xs={12} className="mb-2">
                                <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() => handleDelete(donationData.id)}
                                    style={{
                                        backgroundColor: '#d9534f', // Bright red
                                        color: 'white',
                                        width: '100%',
                                        padding: '10px',
                                        border: 'none',
                                        borderRadius: '5px'
                                    }}
                                >
                                    Cancel / Delete All the Details of this Transaction
                                </Button>
                            </Col>
                            <Col xs={12} className="mb-2">
                                <Button
                                    variant="secondary"
                                    onClick={() => navigate("/payment-selection", { state: { donationData } })}
                                    style={{
                                        backgroundColor: '#6c757d', // Soft gray
                                        color: 'white',
                                        width: '100%',
                                        padding: '10px',
                                        border: 'none',
                                        borderRadius: '5px',
                                    }}
                                >
                                    Back to Payment Selection
                                </Button>

                            </Col>
                            <Col xs={12}>
                                <Button
                                    variant="success"
                                    onClick={handleConfirm}
                                    style={{
                                        backgroundColor: '#5cb85c', // Bright green
                                        color: 'white',
                                        width: '100%',
                                        padding: '10px',
                                        border: 'none',
                                        borderRadius: '5px'
                                    }}
                                >
                                    Confirm and Submit Donation Details
                                </Button>
                            </Col>
                        </Row>


                    </Form>
                </Card>

                {/* Thank You Modal */}
                <Modal show={showDialog} onHide={handleCloseDialog}>
                    <Modal.Header closeButton>
                        <Modal.Title>Thank You!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {isMalayalam ? (<div>
                            <p>താങ്കളുടെ സംഭാവന വിജയകരമായി സമർപ്പിച്ചിരിക്കുന്നു!</p>
                            <p>അല്പസമയത്തിനുള്ളിൽ താങ്കൾക്ക് ജെറോമിൽ നിന്ന് ഒരു കൈയെഴുത്ത് കത്ത് ഉൾപ്പെടെയുള്ള ഇമെയിൽ ലഭിക്കും.</p>
                            <p>ഞങ്ങൾ സംഭാവനയുടെ സ്ഥിരീകരണം നടത്തിയതിനുശേഷം, സമാഹരിച്ച ഫണ്ടിന്റെ വിശദാംശങ്ങൾ  ഹോം പേജിൽ ഉടൻ അപ്‌ഡേറ്റ് ചെയ്യുകയും ചെയ്യും.</p>
                            <p> നിങ്ങളുടെ പിന്തുണയ്ക്കും ഉദാരതയ്ക്കും ഞങ്ങൾ ഹൃദയപൂർവ്വം നന്ദി പറയുന്നു! </p>
                            <p>ദൈവത്തിന്റെ അനുഗ്രഹം താങ്കളുടെ മേൽ ഉണ്ടായിരിക്കട്ടെ 🙏</p>
                        </div>
                        ) : (<div>

                            <p>Your donation has been successfully submitted! </p>
                            <p>You will be receiving an email with a handwritten letter from Jerome shortly.</p>
                            <p>We will verify the donation and update the details of the fund raised on the Home Page shortly.</p>
                            <p> We deeply appreciate your support and generosity! </p>
                            <p>God Bless you 🙏</p>
                        </div>
                        )}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseDialog}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


                {/* whatsapp container*/}
                {showWhatsApp && (
                    <div
                        id="whatsapp-container"
                        style={{
                            position: "fixed",
                            bottom: "20px", // Position the container at the bottom
                            right: "20px", // Position the container on the right
                            display: "flex", // Use flexbox for text and icon alignment
                            alignItems: "center", // Vertically align text to the middle of the icon
                            zIndex: 1000,
                            animation: "popUpBounce 0.6s ease-out", // Bounce animation for the icon
                        }}
                    >
                        {/* Message to the left of the WhatsApp button */}
                        <span
                            style={{
                                marginRight: "10px", // Space between the text and the icon
                                padding: "8px 12px", // Padding for better visibility
                                color: "rgba(0, 0, 0, 0.85)", // Text color
                                fontSize: "14px", // Font size for readability
                                backgroundColor: "rgba(37, 211, 102, 0.3)", // Greenish background
                                borderRadius: "5px", // Rounded corners
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)", // Subtle shadow
                                animation: "unfoldText 7s ease-in-out forwards", // Unfold and disappear animation
                                whiteSpace: "nowrap", // Prevent text wrapping
                                overflow: "hidden", // Hide overflow during animations
                                display: "inline-block", // Ensure inline block behavior
                                transformOrigin: "right center", // Start unfolding from the right
                            }}
                        >
                            Whatsapp us for any help
                        </span>

                        {/* WhatsApp Floating Chat Button */}
                        <a
                            href="https://wa.me/+919744661203?text=Hello%20I%20need%20assistance"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                backgroundColor: "#25D366",
                                borderRadius: "50%",
                                width: "50px",
                                height: "50px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "0 4px 8px rgba(0,0,0,0.2)", // Enhanced shadow
                                animation: "pulse 1.5s infinite", // Pulse effect for the icon
                            }}
                        >
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                                alt="WhatsApp"
                                style={{
                                    width: "32px",
                                    height: "32px",
                                }}
                            />
                        </a>

                        {/* Global CSS Styling */}
                        <style>
                            {`
        /* Bounce animation for the WhatsApp icon */
        @keyframes popUpBounce {
          0% {
            transform: translateY(100%);
            opacity: 0;
          }
          60% {
            transform: translateY(-10%);
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }

        /* Pulse effect for the WhatsApp icon */
        @keyframes pulse {
          0% {
            transform: scale(1);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }
          50% {
            transform: scale(1.1);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
          }
          100% {
            transform: scale(1);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }
        }

        /* Unfold and disappear animation for the help text */
        @keyframes unfoldText {
          0% {
            transform: scaleX(0); /* Start folded (hidden) */
            opacity: 0; /* Invisible initially */
          }
          10% {
            transform: scaleX(1); /* Fully unfolded */
            opacity: 1; /* Fully visible */
          }
          80% {
            transform: scaleX(1); /* Remain visible */
            opacity: 1;
          }
          100% {
            transform: scaleX(0); /* Fold back to hidden */
            opacity: 0; /* Invisible */
          }
        }

        /* Mobile view adjustments */
        @media (max-width: 768px) {
          #whatsapp-container {
            right: 30px; /* Adjust position for mobile */
            bottom: 15px; /* Adjust bottom for better fit */
          }
        }
      `}
                        </style>
                    </div>
                )};
            </Container>
            <div style={{ display: "none" }}>
                <style>
                    {`
                    @keyframes zoom {
                        0% { transform: scale(1); }
                        50% { transform: scale(1.1); } /* Zoom in slightly */
                        100% { transform: scale(1); } /* Zoom out */
                    }
                `}
                </style>
            </div>

        </div>
    );
};

export default ConfirmDonationLink;
